import styled from 'styled-components';

import { Box, Heading } from 'grommet';

export const StyledTableauStudiesIllustrationWrapper = styled(Box)`
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: right 12% center;

  @media (max-width: 768px) {
    background-position: 50% 30%;
    background-size: 55%;
    height: auto;
    min-height: 100vh;
    max-height: none;
  }

  @media (max-width: 450px) {
    background-position: 50% 20%;
    background-size: 70%;
  }
`;

export const StyledPreHeading = styled(Heading)`
  letter-spacing: normal;
  font-weight: 800;
  font-size: 1.2rem;
  color: white;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

export const StyledPreHeadingContainer = styled(Box)`
  border-radius: 2.5rem;
  background-color: ${({ background }) => background};
  width: fit-content;
  padding: 0.3rem 1.25rem;
  border: 1px solid white;
`;
