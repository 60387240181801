'use client';

import React from 'react';
import PropTypes from 'prop-types';
import Case from 'case';
import uniqid from 'uniqid';
import _ from 'lodash';
import styled from 'styled-components';

import {
  Box, Text, Image,
} from 'grommet';

import { useClientMediaQuery } from '@/lib/clientMediaQuery';
import { optimizeImageSrc } from '@/helpers/util';
import {
  AppAnchor, AppButton, FullHeightBox, Spinning,
} from '@/components/Control';
import { paths } from '@/components/configs';

import { ScrollerHeader } from '../ServiceDynamic/ContentSections/ContentSections';
import { StyledScrollerHeader, StyledResourcesAnchor } from '../ServiceDynamic/ContentSections/StyledContentSections';
import SuperBowlStudiesPage from '../TableauStudies';


export const ClientStudyCard = ({
  small,
  smallerCard = false,
  clientUid,
  title,
  coverImage,
  textColor,
  highlightColor,
}) => (
  <StyledResourcesAnchor
    isSmall={small}
    path={paths.caseStudy.replace(':uid', clientUid)}
    boxShadow="0px 2px 7px 0px rgba(8, 0, 102, 0.18)"
    hoverShadow="0px 6px 11px 0px rgba(8, 0, 102, 0.2)"
    cardBg="white"
  >
    <Box
      direction="column"
      gap="1rem"
      height={smallerCard ? '22rem' : '25rem'}
      width={smallerCard ? '19rem' : '22rem'}
      justify="between"
    >
      <Box round={{ size: '0.625rem' }} width="100%" height="11.5rem" overflow="hidden" pad="0.2rem">
        <Image
          src={optimizeImageSrc(coverImage)}
          alt={`${Case.capital(clientUid)} image`}
          fit="cover"
        />
      </Box>
      <Box
        flex
        direction="column"
        gap="1rem"
        justify="center"
        align="center"
        pad={{ horizontal: '1.25rem', bottom: '1.25rem' }}
      >
        <Text
          size={smallerCard ? '1.3rem' : '1.5rem'}
          weight={600}
          color={textColor}
          textAlign="center"
        >
          {title}
        </Text>
        <AppButton
          overrideHover
          level="dynamicLarge"
          bgColor={highlightColor}
          color="white"
          label="View Study"
          fontWeight={600}
        />
      </Box>
    </Box>
  </StyledResourcesAnchor>
);

ClientStudyCard.propTypes = {
  small: PropTypes.bool.isRequired,
  smallerCard: PropTypes.bool,
  clientUid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  coverImage: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
};

const StyledHoverCard = styled(Box)`
  img {
    box-shadow: 0px 2px 7px 0px rgba(8, 0, 102, 0.16);
  }

  &:hover img {
    box-shadow: 0px 5px 10px 0px rgba(8, 0, 102, 0.18);
  }
`;

export const InteractiveStudyCard = ({
  small, studyUid, coverImage, textColor,
}) => (
  <StyledHoverCard width={small ? '50%' : '25%'} align="center" justify="center" pad={small ? '1.5rem' : '2rem'}>
    <AppAnchor path={paths.caseStudy.replace(':uid', studyUid)}>
      <Box flex={false} direction="column" gap="1rem" height={small ? '20rem' : '22rem'} width={small ? '14rem' : '15rem'}>
        <Image
          fill
          src={optimizeImageSrc(coverImage)}
          alt={`${Case.capital(studyUid)} case study`}
          fit="cover"
        />
        <Box direction="column" gap="0.25rem">
          <Text color={textColor} size="1.5rem" weight={600} textAlign="center">{Case.capital(studyUid)}</Text>
          <Text color={textColor} size="1.25rem" weight={400} textAlign="center">(interactive)</Text>
        </Box>
      </Box>
    </AppAnchor>
  </StyledHoverCard>
);

InteractiveStudyCard.propTypes = {
  small: PropTypes.bool.isRequired,
  studyUid: PropTypes.string.isRequired,
  coverImage: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

const StudyEmbed = ({
  small, currentUid, embedUrl, highlightColor, ...rest
}) => {
  if (currentUid === 'sports') {
    return (
      <Box height="100%">
        <SuperBowlStudiesPage small={small} highlightColor={highlightColor} {...rest} />
        <Box justify="center" align="center" pad={{ top: '1.5rem' }}>
          <tableau-viz
            hide-tabs
            suppress-default-edit-behavior
            id="tableau-viz"
            device={small ? 'phone' : 'desktop'}
            src={embedUrl}
            toolbar="hidden"
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box height="100%" justify="center" align="center">
      <tableau-viz
        hide-tabs
        suppress-default-edit-behavior
        id="tableau-viz"
        device={small ? 'phone' : 'desktop'}
        src={embedUrl}
        toolbar="hidden"
      />
    </Box>
  );
};

StudyEmbed.propTypes = {
  small: PropTypes.bool.isRequired,
  currentUid: PropTypes.string.isRequired,
  embedUrl: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
};

const StyledStudyContainer = styled(Box)`
  width: min-content;
  min-width: var(--vw48);

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CaseStudy = ({ config = null, currentUid }) => {
  const small = useClientMediaQuery('(max-width: 768px)');

  if (!config) {
    return (
      <FullHeightBox pad={{ top: '5.2rem' }} background="white" justify="center" align="center">
        <Spinning color="white" size={small ? 'large' : 'xlarge'} />
      </FullHeightBox>
    );
  }

  const {
    highlightColor,
    embedsSectionHeader,
    embedsSectionSubheader,
    embedsBg,
    embedsTitleColor,
    embedStudyBanner,
    clientStudiesBg,
    clientStudiesHeader,
    primaryTextColor,
    caseStudies,
    clientStudies,
    ...rest
  } = config;

  let currentStudy = null;
  let isClientStudy = false;

  if (currentUid.includes('client')) {
    currentStudy = _.find(clientStudies, { uid: currentUid });
    isClientStudy = true;
  } else {
    currentStudy = _.find(caseStudies, { uid: currentUid });
  }

  const studiesData = isClientStudy ? clientStudies : caseStudies;
  const restStudies = _.filter(studiesData, ({ uid }) => uid !== currentUid);
  const bgColor = isClientStudy ? clientStudiesBg : embedsBg;

  if (isClientStudy && currentStudy) {
    return (
      <FullHeightBox
        background={bgColor}
        overflow="hidden"
        direction="column"
        pad={{ top: '5.375rem', horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)' }}
      >
        <Box direction={small ? 'column' : 'row'} pad={{ bottom: '4.5rem', top: small ? '3rem' : '4.5rem' }}>
          <Box flex direction="column" gap="2rem">
            <Box width="100%" direction="column">
              {currentStudy.pageImages.map((imageSrc) => (
                <Box width="100%" key={uniqid()}>
                  <Image
                    fill
                    style={{ borderRadius: '8px' }}
                    src={small ? `${optimizeImageSrc(imageSrc)}&w=768` : optimizeImageSrc(imageSrc)}
                    alt={`${currentStudy.title} client study`}
                    fit="contain"
                  />
                </Box>
              ))}
            </Box>
            <Box align="center" justify="center">
              <AppAnchor download href={currentStudy.pdf} target="_blank">
                <AppButton
                  overrideHover
                  level="dynamicLarge"
                  width="12rem"
                  bgColor={highlightColor}
                  color="white"
                  label="Download Case Study"
                  fontWeight={600}
                />
              </AppAnchor>
            </Box>
          </Box>
          <Box
            direction="column"
            justify="start"
            align="center"
            gap={small ? '1rem' : '0.5rem'}
            width={small ? '100%' : '22rem'}
            pad={{ top: small ? '3rem' : '0' }}
          >
            <Box width="100%">
              <Text
                uppercase
                color="#65768E"
                size="1rem"
                weight={600}
                textAlign="center"
                styled={{ letterSpacing: '2.8px' }}
              >
                Other client studies
              </Text>
            </Box>
            {restStudies.map(({
              uid: clientUid, coverImage, title,
            }) => (
              <ClientStudyCard
                key={uniqid()}
                small={small}
                smallerCard={!small}
                clientUid={clientUid}
                title={title}
                coverImage={coverImage}
                textColor={primaryTextColor}
                highlightColor={highlightColor}
              />
            ))}
          </Box>
        </Box>
      </FullHeightBox>
    );
  }

  const {
    embedUrl, title, description, ...restProps
  } = currentStudy;

  return (
    <FullHeightBox direction="column" pad={{ top: '5.375rem' }}>
      <Box pad={{ vertical: '3rem' }} background={{ image: `url(${embedStudyBanner})`, color: bgColor }}>
        <StyledScrollerHeader
          level={2}
          color="white"
          margin="none"
          textAlign="center"
        >
          {title}
        </StyledScrollerHeader>
      </Box>
      <Box direction="column" pad={{ vertical: '3rem', horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)' }} gap="3rem" align="center" justify="center">
        <StyledStudyContainer gap="3rem">
          <Box direction="column" gap="1rem">
            {description.map((dt) => (
              <Text key={uniqid()} color={primaryTextColor} size="1rem" weight={400} textAlign="start">{dt}</Text>
            ))}
          </Box>
          <Box direction="column" gap="1rem">
            <StudyEmbed
              small={small}
              currentUid={currentUid}
              embedUrl={embedUrl}
              highlightColor={highlightColor}
              {...restProps}
            />
            {rest.disclaimer && (
              <Box pad={{ left: '2%' }}>
                <Text color={primaryTextColor} size="0.85rem" weight={400} textAlign="start">{rest.disclaimer}</Text>
              </Box>
            )}
          </Box>
        </StyledStudyContainer>
        <Box width="100%" direction="column" gap="1rem" justify="center" align="center">
          <ScrollerHeader header="See our other interactive use cases" color={primaryTextColor} textAlign="center" />
          <Box wrap={small} direction="row" width="100%" justify={small ? 'start' : 'center'}>
            {restStudies.map(({ uid: studyUid, coverImage }) => (
              <InteractiveStudyCard
                key={uniqid()}
                small={small}
                studyUid={studyUid}
                coverImage={coverImage}
                textColor={primaryTextColor}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </FullHeightBox>
  );
};

CaseStudy.propTypes = {
  currentUid: PropTypes.string.isRequired,
  config: PropTypes.shape({
    fullHeightHeadSection: PropTypes.bool,
    preHeader: PropTypes.string,
    preHeaderColor: PropTypes.string,
    header: PropTypes.string.isRequired,
    desktopHeader: PropTypes.string,
    desktopHeaderLogo: PropTypes.string,
    subHeader: PropTypes.arrayOf(PropTypes.string.isRequired),
    headerColor: PropTypes.string.isRequired,
    headersBg: PropTypes.string,
    image: PropTypes.string,
    fullPageBgImage: PropTypes.string,
    fullPageBgImageMobile: PropTypes.string,
    customBgColor: PropTypes.string.isRequired,
    highlightColor: PropTypes.string.isRequired,
    headerApproach: PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      hoverColor: PropTypes.string.isRequired,
    }),
    embedsBg: PropTypes.string.isRequired,
    embedsTitleColor: PropTypes.string.isRequired,
    embedsSectionHeader: PropTypes.string.isRequired,
    embedsSectionSubheader: PropTypes.string.isRequired,
    embedStudyBanner: PropTypes.string.isRequired,
    clientStudiesBg: PropTypes.string.isRequired,
    clientStudiesHeader: PropTypes.string.isRequired,
    primaryTextColor: PropTypes.string.isRequired,
    caseStudies: PropTypes.arrayOf(PropTypes.shape({
      uid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      embedUrl: PropTypes.string.isRequired,
      description: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      coverImage: PropTypes.string.isRequired,
      carouselImages: PropTypes.arrayOf(PropTypes.string.isRequired),
      fullScreenBg: PropTypes.string,
      disclaimer: PropTypes.string,
      preHeader: PropTypes.string,
      header: PropTypes.string,
      subHeader: PropTypes.string,
      headersColor: PropTypes.string,
      highlightColor: PropTypes.string,
      customBgColor: PropTypes.string,
    }).isRequired).isRequired,
    clientStudies: PropTypes.arrayOf(PropTypes.shape({
      uid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      coverImage: PropTypes.string.isRequired,
      pageImages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      pdf: PropTypes.string.isRequired,
    }).isRequired).isRequired,
  }),
};

export default CaseStudy;
