import React from 'react';
import styled from 'styled-components';

import { ReactSVG } from 'react-svg';
import { Carousel } from 'react-responsive-carousel';

import {
  Box, Heading, Text, Tab, Button, Accordion,
} from 'grommet';
import { LazyVideoLoader, AppAnchor } from '@/components/Control';


const StyledDemoVideo = styled((props) => <LazyVideoLoader {...props} />)`
  && { align-self: center; }
`;

const StyledFlexGrowContainer = styled(Box)`
  flex: 1 1;
  flex-grow: ${({ flexGrow }) => flexGrow || 1};
  border: ${({ boxBorder }) => boxBorder || 'none'};
  box-shadow: ${({ boxShadow }) => boxShadow || 'none'};
`;

const StyledScrollerHeader = styled(Heading)`
  font-size: ${({ sizeOverrides }) => (sizeOverrides ? sizeOverrides.large : '2rem')};
  line-height: 1.2;
  letter-spacing: normal;
  white-space: pre-line;
  font-weight: ${({ weight }) => (weight || 600)};

  @media (max-width: 1070px) {
    font-size: ${({ sizeOverrides }) => (sizeOverrides ? sizeOverrides.medium : '1.85rem')};
  }

  @media (max-width: 768px) {
    white-space: normal;
    font-size: ${({ sizeOverrides }) => (sizeOverrides ? sizeOverrides.small : '1.75rem')};
  }

  @media (max-width: 320px) {
    font-size: ${({ sizeOverrides }) => (sizeOverrides ? sizeOverrides.xsmall : '1.5rem')};
  }
`;

const StyledScrollerSubHeader = styled(Heading)`
  font-weight: ${({ weight }) => (weight || 400)};
  letter-spacing: normal;
  white-space: pre-line;
  margin: none;
`;

const StyledTextContent = styled(Text)`
  font-size: ${({ size }) => (size || '1rem')};
  line-height: ${({ lineHeight }) => (lineHeight || '1.35')};
  letter-spacing: normal;
  white-space: pre-line;

  @media (max-width: 768px) {
    font-size: ${({ size }) => (size || '1.125rem')};
  }
`;

const StyledSmallHeader = styled(Text)`
  letter-spacing: normal;
  line-height: 1.2;
  white-space: pre-line;

  ${({ underlineColor }) => underlineColor && `
    position: relative;
    line-height: 1.8;

    &:after {
      content: '';
      display: block;
      position: relative;
      top: 0;
      left: 0;
      height: 4px;
      width: 45px;
      background-color: ${underlineColor};
      transition: all 0.35s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
  `}

  ${({ adjustSize }) => adjustSize && `
    font-size: 1rem;

    @media (min-width: 768px) and (max-width: 1070px) {
      font-size: 0.9rem;
    }
  `}
`;

const StyledCardWrapper = styled(Box)`
  width: ${({ cardWidth }) => cardWidth};
  min-width: ${({ cardWidth }) => cardWidth};

  @media (max-width: 768px) {
    width: ${({ mobileCardWidth }) => mobileCardWidth ?? 'calc(42% - 1rem)'};
    min-width: ${({ mobileCardWidth }) => mobileCardWidth ?? 'calc(42% - 1rem)'};
  }

  @media (max-width: 365px) {
    width: ${({ mobileCardWidth }) => mobileCardWidth ?? 'calc(50% - 1rem)'};
    min-width: ${({ mobileCardWidth }) => mobileCardWidth ?? 'calc(50% - 1rem)'};
  }
`;

const StyledGridCardContainer = styled(Box)`
  background: ${({ cardBg }) => cardBg || 'none'};
  height: 100%;
  box-shadow: ${({ boxShadow }) => boxShadow || 'none'};
  border-radius: 10px;
`;

const StyledGridCardAnchorContainer = styled((props) => <AppAnchor {...props} />)`
  background: ${({ cardBg }) => cardBg || 'none'};
  height: 100%;
  box-shadow: ${({ boxShadow }) => boxShadow || 'none'};
  border-radius: 10px;
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);

  svg > path {
    transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  span {
    transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  &:hover {
    svg > path {
      stroke: ${({ hoverColor }) => hoverColor};
    }

    span {
      color: ${({ hoverColor }) => hoverColor};
    }
  }
`;

const StyledTabScrollerTab = styled(Tab)`
  &[aria-selected="true"] {
    span { color: white; }

    div {
      background: ${({ selectedBg }) => selectedBg};
    }
  }

  div {
    border-bottom: none;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    background: ${({ bgColor }) => bgColor};
    margin-right: ${({ isSmall }) => (isSmall ? '0.5rem' : '1rem')};
    box-shadow: rgba(0, 0, 0, 0.19) 0px 9px 19px, rgba(0, 0, 0, 0.23) 0px 5px 5px;
    margin-bottom: ${({ isSmall }) => (isSmall ? '1.75rem' : '1rem')};
    margin-top: ${({ isSmall }) => (isSmall ? '1.75rem' : '1rem')};
    padding-bottom: 0;
    border-radius: 2.5rem;
    min-width: ${({ isSmall }) => (isSmall ? '12rem' : '13.5rem')};
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    ${({ isSmall }) => isSmall && `
      box-shadow: rgba(0, 0, 0, 0.19) 0px 3px 7px, rgba(0, 0, 0, 0.23) 0px 3px 3px;
    `}

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }

    span {
      color: ${({ textColor }) => textColor};
      padding-left: ${({ isSmall }) => (isSmall ? 'var(--vw1p25)' : '1.5rem')};
      padding-right: ${({ isSmall }) => (isSmall ? 'var(--vw1p25)' : '1.5rem')};
      padding-top: ${({ isSmall }) => (isSmall ? '0.85rem' : '1.25rem')};
      padding-bottom: ${({ isSmall }) => (isSmall ? '0.85rem' : '1.25rem')};
      font-weight: 700;
      font-size: ${({ isSmall }) => (isSmall ? '1rem' : '1.25rem')};
    }
  }
`;

const StyledAspectScrollerDemo = styled(Box)`
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : 'none')};
  box-sizing: border-box;

  video {
    -webkit-flex: inherit;
    -ms-flex: inherit;
    flex: initial;
  }
`;

const StyledPlatformIconContainer = styled(Box)`
  box-shadow: ${({ boxShadow }) => boxShadow};
  border-radius: 2.5rem;

  > picture > img {
    transform: scale(0.8);
  }
`;

const StyledUseCaseSlideContainer = styled(Box)`
  background: ${({ bgColor }) => bgColor};
  height: ${({ isSmall }) => (isSmall ? '14rem' : '12rem')};
  width: ${({ isSmall }) => (isSmall ? '25rem' : '32rem')};
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: ${({ boxShadow, isActive }) => (isActive ? boxShadow : 'none')};
  border-radius: 2.5rem;

  @media (max-width: 400px) {
    height: 100%;
    min-height: 16rem;
  }
`;

const StyledIconButton = styled(Button)`
  :focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
  }

  svg {
    border-radius: 50%;
    padding: 9px;
    transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  &:hover {
    svg {
      fill: ${({ highlight }) => highlight};
      stroke: ${({ highlight }) => highlight};
    }
  }
`;

const StyledIndustryCardAnchor = styled((props) => <AppAnchor {...props} />)`
  text-decoration: none;
  box-shadow: ${({ boxShadow }) => boxShadow || 'none'};
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  height: 20rem;
  width: 30%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  &:hover {
    text-decoration: none;
    box-shadow: ${({ hoverShadow }) => hoverShadow || 'none'};

    .underline:after {
      width: 80px;
    }
  }

  ${({ revealContent, isSmall }) => revealContent && `
    .overlay {
      opacity: ${isSmall ? '1' : '0'};
      background: rgba(0, 0, 0, 0.65);
      transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    &:hover {
      text-decoration: none;

      .overlay {
        opacity: 1;
        text-decoration: none;
      }
    }
  `}

  @media (min-width: 768px) and (max-width: 1070px) {
    height: 22rem;
    width: 45%;
  }

  @media (max-width: 768px) {
    height: 22rem;
    width: 100%;
  }
`;

const StyledFillerCard = styled(Box)`
  width: 30%;
  height: 17.6rem;

  @media (min-width: 768px) and (max-width: 1070px) {
    width: 45%;
  }

  @media (max-width: 768px) {
    height: 15rem;
    width: 100%;
  }
`;

const StyledCardImage = styled(Box)`
  background-repeat: no-repeat;
  background-size: ${({ bgSize }) => bgSize || '100%'};
  background-position: center;
`;

const StyledAnalysisCardWrapper = styled(Box)`
  border-radius: ${({ borderRadius }) => borderRadius || '2.5rem'};
  box-shadow: ${({ boxShadow }) => boxShadow || 'none'};
`;

const StyledTechStackContainer = styled(Box)`
  box-shadow: ${({ boxShadow }) => boxShadow};
`;

const StyledIntroTextAnchor = styled((props) => <AppAnchor {...props} />)`
  text-decoration: none;
  background-image: ${({ borderImage }) => `url(${borderImage})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 34px;

  > div {
    border-radius: 39px;
    transition: border 0.17s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-top: 3px solid rgba(0,0,0,0);
    border-bottom: 3px solid rgba(0,0,0,0);
    width: 285px;
    height: 90px;
  }

  span {
    white-space: pre-line;
  }

  .iconContainer {
    min-width: ${({ width }) => width};
    min-height: ${({ height }) => height};
    width: 100px;
    height: 90px;
    padding: 0.2rem;
  }

  &:hover {
    text-decoration: none;
    background-color: rgba(0,0,0,0.20);

    > div {
      border-top: 3px solid white;
      border-bottom: 3px solid white;
    }
  }

  @media (min-width: 1845px) {
    > div {
      width: 315px;
      height: 105px;

      .iconContainer {
        width: 110px;
        height: 105px;
        padding: 0.4rem;
      }
    }
  }
`;

const StyledAnchorButtonWrapper = styled(Box)`
  flex-direction: row;

  a:first-child {
    margin-right: 2rem;
  }

  @media (max-width: 1070px) {
    flex-direction: column;

    a:first-child {
      margin-right: 0;
    }
  }
`;

const StyledTrendingHeader = styled(Heading)`
  font-weight: 800;
  line-height: 1.25;
  font-size: 1.25rem;
  white-space: pre-line;
  letter-spacing: normal;
`;

const StyledSectionHeader = styled(Heading)`
  font-weight: 600;
`;

const StyledTrendingTitle = styled(Text)`
  font-weight: 600;
  font-size: ${({ size }) => (size || '1rem')};
  line-height: ${({ lineHeight }) => (lineHeight || '1.35')};
  letter-spacing: normal;
  white-space: pre-line;

  @media (max-width: 768px) {
    font-size: ${({ size }) => (size || '1.125rem')};
  }
`;

const StyledResourcesAnchor = styled((props) => <AppAnchor {...props} />)`
  text-decoration: none;
  background: ${({ cardBg }) => cardBg ?? 'rgba(0,0,0,0)'};
  border-radius: 0.625rem;
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: fit-content;

  img {
    border-radius: 0.625rem;
  }
`;

const StyledEmbedContainer = styled((props) => <AppAnchor {...props} />)`
  text-decoration: none;
  box-shadow: ${({ boxShadow }) => boxShadow || 'none'};
  border-radius: 0.625rem;
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: white;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-width: ${({ minWidth }) => minWidth || '0'};

  &:hover {
    text-decoration: none;
    box-shadow: ${({ hoverShadow }) => hoverShadow};
  }
`;

const StyledDecorativeText = styled(Text)`
  font-size: ${({ isBold, regularSize, boldSize }) => (isBold ? boldSize : regularSize)};
  color: ${({ isBold, regularColor, boldColor }) => (isBold ? boldColor : regularColor)};
  font-weight: ${({ isBold }) => (isBold ? 800 : 'normal')};
  text-transform: ${({ isBold }) => (isBold ? 'uppercase' : 'none')};
  padding-left: ${({ isSmall }) => (isSmall ? '0.25rem' : '0.4rem')};
  padding-right:${({ isSmall }) => (isSmall ? '0.25rem' : '0.4rem')};
`;

const StyledSVG = styled(ReactSVG)`
  svg {
    height: ${({ height }) => height};
    width:${({ width }) => width};
  }
`;

const StyledBambooWidget = styled.div`
  * {
    text-align: left;
  }

  h2 {
    font-size: 22px;
    color: ${({ color }) => color};
    border-bottom: none !important;
    margin-bottom: 0.25rem;
    font-weight: 600;
  }

  ul { font-size: 14px; }

  ul > ul:last-of-type { margin-bottom: 0px; }

  li {
    color: ${({ color }) => color};

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      padding-bottom: 1.25rem;

      span {
        text-align: start;
        padding-top: 5px !important;
        font-size: 13px;
        color: #D0D0D0;
      }
    }
  }

  a {
    text-decoration: none;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.9em;
    color: ${({ color }) => color};

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .BambooHR-ATS-blankState {
    color: #adadad;
    font-size: 15px;
  }

  .BambooHR-ATS-Jobs-List {
    padding: 0.5rem 0.5rem 1rem 0.5rem !important;
  }

  .BambooHR-ATS-Department-Header {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    color: ${({ $highlight }) => $highlight};
  }

  strong {
    font-size: 16px;
    font-weight: 600;
  }

  #BambooHR-ATS + div {
    display: none !important;
  }

  img { display: inline; }
`;

const StyledServicesAnchor = styled((props) => <AppAnchor {...props} />)`
  width: ${({ isSmall }) => (isSmall ? '100%' : '50%')};
`;

const StyledFAQAccordion = styled((props) => <Accordion {...props} />)`
  > div {
    margin-bottom: 1rem;
    border-radius: 6px;
    box-shadow: ${({ boxShadow }) => boxShadow ?? 'none'};
  }

  button[aria-expanded="false"] {
    border-radius: 6px;

    ${({ hoverHighlight }) => hoverHighlight && (`
      &:hover {
        span.panelHeader {
          color: ${hoverHighlight};
        }

        svg.panelOpen {
          fill: ${hoverHighlight};
          stroke: ${hoverHighlight};
        }
      }
    `)};
  }

  button[aria-expanded="true"] {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  div[role="region"] {
    border-bottom: none;
  }
`;

const StyledIconCardsWrapper = styled(Box)`
  height: 13.5rem;

  @media (max-width: 1070px) {
    height: 16rem;
  }

  @media (max-width: 768px) {
    height: 14.5rem;
  }
`;

const StyledCarousel = styled(Carousel)`
  margin: 1rem;
  box-shadow: ${({ boxShadow }) => boxShadow};
  border-radius: 0.625rem;
  height: ${({ height }) => height};

  ul {
    height: 100%;
  }

  .carousel-slider, .carousel-wrapper, .slider-wrapper {
    height: 100%;
  }
`;

export {
  StyledAspectScrollerDemo,
  StyledDemoVideo,
  StyledFlexGrowContainer,
  StyledCardWrapper,
  StyledGridCardContainer,
  StyledGridCardAnchorContainer,
  StyledSectionHeader,
  StyledTabScrollerTab,
  StyledScrollerHeader,
  StyledScrollerSubHeader,
  StyledTextContent,
  StyledSmallHeader,
  StyledPlatformIconContainer,
  StyledUseCaseSlideContainer,
  StyledIconButton,
  StyledIndustryCardAnchor,
  StyledFillerCard,
  StyledAnalysisCardWrapper,
  StyledTechStackContainer,
  StyledIntroTextAnchor,
  StyledAnchorButtonWrapper,
  StyledTrendingHeader,
  StyledTrendingTitle,
  StyledResourcesAnchor,
  StyledCardImage,
  StyledEmbedContainer,
  StyledDecorativeText,
  StyledSVG,
  StyledBambooWidget,
  StyledServicesAnchor,
  StyledFAQAccordion,
  StyledIconCardsWrapper,
  StyledCarousel,
};
