import React from 'react';
import styled from 'styled-components';

import {
  Box, Heading, Text,
} from 'grommet';

import { LazyVideoLoader } from '@/components/Control';

export const StyledIntroductionWrapper = styled(Box)`
  @media (min-width: 48.0625em) {
    max-width: ${({ adjustSize }) => (adjustSize ? '55%' : '38rem')};
    min-width: 26rem;
  }
`;

export const StyledIllustrationWrapper = styled(Box)`
  min-height: ${({ fullHeight, sectionHeight }) => (fullHeight ? 'min(35vw, 1440px)' : sectionHeight)};
  background-repeat: no-repeat;
  background-size: 45%;
  max-height: min(100vh, 1600px);
  background-position: right 8% center;
  ${({ sectionHeight, fullHeight }) => sectionHeight && !fullHeight && `
    height: ${sectionHeight};
  `}

  @media (max-width: 1070px) {
    min-height: min(55vw, 1440px);
  }

  @media (max-width: 768px) {
    background-position: 50% 5%;
    background-size: 60%;
    height: auto;
    min-height: ${({ fullHeight }) => (fullHeight ? '75vh' : '40vh')};
    max-height: none;
  }

  @media (max-width: 450px) {
    background-size: 90%;
  }
`;

export const StyledHeader = styled(Heading)`
  letter-spacing: normal;
  font-size: ${({ smallerHeader }) => (smallerHeader ? '2rem' : '2.85rem')};
  font-weight: 600;
  white-space: pre-line;
  line-height: 1.2;

  @media (max-width: 1070px) {
    font-size: ${({ smallerHeader }) => (smallerHeader ? '1.85rem' : '2.25rem')};
  }

  @media (max-width: 768px) {
    font-size: ${({ smallerHeader }) => (smallerHeader ? '2rem' : '2.65rem')};
  }

  @media (max-width: 375px) {
    font-size: ${({ smallerHeader }) => (smallerHeader ? '1.85rem' : '2.5rem')};
  }
`;

export const StyledSubHeader = styled(Heading)`
  letter-spacing: ${({ spacing }) => spacing || 'normal'};
  font-weight: ${({ weight }) => weight || 'normal'};
  font-size: ${({ size }) => (size || '1rem')};
  line-height: ${({ lineHeight }) => (lineHeight || '1.35')};

  @media (max-width: 768px) {
    font-size: ${({ size }) => (size || '1.125rem')};
  }
`;

export const StyledPreHeader = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.235px;
  font-weight: ${({ weight }) => weight || 600};
  font-size: ${({ size }) => (size || '1rem')};
  line-height: 1.35;

  @media (max-width: 768px) {
    font-size: ${({ size }) => (size || '1.125rem')};
  }
`;

export const StyledDemoVideo = styled((props) => <LazyVideoLoader {...props} />)`
  && {
    max-height: 760px;
    align-self: center;
  }
`;
