'use client';

import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import {
  Box, Text, Button, Image,
} from 'grommet';
import { InlineWidget } from 'react-calendly';

import { useClientMediaQuery } from '@/lib/clientMediaQuery';
import { optimizeImageSrc } from '@/helpers/util';
import {
  Container, FullHeightBox, AppAnchor, AppButton,
} from '@/components/Control';

import FAQScroller from '../ServiceDynamic/ContentSections/FAQScroller';
import {
  StyledMeetingsContainer, StyledFormContainer, StyledHeading, StyledList,
} from './StyledContactPage';


const renderListText = (small, listText, textColor) => (
  <StyledList color={textColor} size="1.1rem" textAlign={small ? 'center' : 'start'}>
    {listText.map((dt) => <li key={uniqid()}>{dt}</li>)}
  </StyledList>
);

const FormSection = ({
  small, title, titleColor, bodyText, textColor, formContainer,
}) => (
  <Box direction="column" gap="1.5rem" align="center" pad={{ horizontal: small ? 'var(--vw5p725)' : '0rem' }}>
    <Box flex direction="column" gap="1.5rem">
      <StyledHeading
        level={1}
        textAlign="center"
        color={titleColor}
        size={small ? '2.5rem' : '3rem'}
        weight={600}
      >
        {title}
      </StyledHeading>
      <Box direction="column" gap="0.5rem">
        {bodyText.map((dt) => {
          if (Array.isArray(dt)) {
            return (renderListText(small, dt, textColor));
          }

          return (
            <Text key={uniqid()} textAlign="center" color={textColor} size="1.1rem">
              {dt}
            </Text>
          );
        })}
      </Box>
    </Box>
    {formContainer}
  </Box>
);

FormSection.propTypes = {
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  bodyText: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  textColor: PropTypes.string.isRequired,
  formContainer: PropTypes.node.isRequired,
};

const ContactPage = ({ config = null }) => {
  const small = useClientMediaQuery('(max-width: 768px)');

  let useImageBg = false;

  React.useEffect(() => {
    if (config && config.formEmbed && !config.embedUrl) {
      const {
        bodyScriptSrc, region, portalId, formId, target,
      } = config.formEmbed;
      const script = document.createElement('script');
      script.src = bodyScriptSrc;
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region, portalId, formId, target,
          });
        }
      });
      return (() => {
        document.body.removeChild(script);
      });
    }

    if (config && config.meetingsEmbed && !config.embedUrl) {
      const script = document.createElement('script');
      script.src = config && config.meetingsEmbed.bodyScriptSrc;
      script.async = true;
      document.body.appendChild(script);
      return (() => {
        document.body.removeChild(script);
      });
    }

    return undefined;
  }, [config]);

  if (!config) return null;

  const {
    pageBg,
    header,
    headerColor,
    paragraph,
    textColor,
    meetingsEmbed,
    formEmbed,
    embedUrl,
    secondSectionConfig,
    thirdSectionConfig,
  } = config;

  if (pageBg.startsWith('https')) {
    useImageBg = true;
  }

  const selectFormContainer = () => {
    if (formEmbed && !embedUrl) {
      return (
        <Box width="100%" pad={{ horizontal: small ? '0' : '15%', bottom: '3.5rem' }}>
          <StyledFormContainer id={formEmbed.target.replace('#', '')} />
        </Box>
      );
    }

    if (config && meetingsEmbed && !embedUrl) {
      return (
        <Box width="100%" pad={{ horizontal: small ? '0' : '15%', bottom: '3.5rem' }}>
          <StyledMeetingsContainer
            className={meetingsEmbed.containerClass}
            data-src={meetingsEmbed.containerDataSrc}
          />
        </Box>
      );
    }

    return (
      <Box width="100%" pad={{ bottom: '3.5rem' }}>
        <InlineWidget url={embedUrl} styles={{ height: small ? '785px' : '735px' }} />
      </Box>
    );
  };

  const handleScroll = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <FullHeightBox
      fill
      pad={{ top: '5.2rem' }}
      data-bg={useImageBg ? optimizeImageSrc(pageBg) : null}
      background={useImageBg ? { image: small ? `url(${optimizeImageSrc(pageBg)}&w=1024)` : `url(${optimizeImageSrc(pageBg)})` } : optimizeImageSrc(pageBg)}
    >
      <Container pad={{ top: '3.5rem' }}>
        <FormSection
          small={small}
          title={header}
          titleColor={headerColor}
          bodyText={paragraph}
          textColor={textColor}
          formContainer={selectFormContainer()}
        />
        <Box direction="column">
          <Box
            background={secondSectionConfig.sectionBg}
            direction="column"
            justify="center"
            gap={small ? '2.5rem' : '3rem'}
            pad={{ horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)', vertical: '4rem' }}
          >
            <Box pad={{ horizontal: small ? '3.5rem' : '0' }}>
              <StyledHeading
                level={2}
                textAlign="center"
                color={secondSectionConfig.textColor}
                size={small ? '2rem' : '1.75rem'}
                weight={600}
              >
                {secondSectionConfig.sectionTitle}
              </StyledHeading>
            </Box>
            <Box
              direction={small ? 'column' : 'row'}
              gap={small ? '3rem' : '0'}
              justify="evenly"
              align="center"
              pad={{ horizontal: small ? '0' : '2.5rem' }}
            >
              {secondSectionConfig.items.map((item) => (
                <Box key={uniqid()} direction="column" gap="1rem" width={small ? '75%' : '20%'}>
                  <Box direction="row" gap="1rem">
                    <Box
                      width={small ? '1.5rem' : '1.25rem'}
                      height={small ? '1.5rem' : '1.25rem'}
                      align="center"
                      justify="center"
                    >
                      <Image src={optimizeImageSrc(item.icon)} alt={item.title} />
                    </Box>
                    <Text textAlign="start" color={secondSectionConfig.textColor} size={small ? '1.3rem' : '1.15rem'} weight={600}>
                      {item.title}
                    </Text>
                  </Box>
                  <Text textAlign="start" color={secondSectionConfig.textColor} size="1rem">
                    {item.text}
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>
          <Button plain onClick={() => handleScroll()}>
            <Box width="100%" pad="0.5rem" align="center" justify="center" background={secondSectionConfig.lowerBannerBg}>
              <Text color={secondSectionConfig.textColor} size="1rem" weight={400}>
                {secondSectionConfig.lowerBannerMessage}
              </Text>
            </Box>
          </Button>
          <Box
            background={thirdSectionConfig.sectionBg}
            direction="column"
            justify="center"
            gap={small ? '2.5rem' : '3rem'}
            pad={{ horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)', vertical: '4rem' }}
          >
            <Box direction="column" gap="0.5rem" pad={{ horizontal: small ? '0' : '15%' }}>
              <FAQScroller small={small} {...thirdSectionConfig.faq} />
              <Box direction="column" background={thirdSectionConfig.lowerSectionBg} justify="center" align="center" gap="1.5rem" pad="2rem">
                <StyledHeading
                  level={2}
                  textAlign="center"
                  color={thirdSectionConfig.lowerSectionTextColor}
                  size={small ? '1.5rem' : '1.35rem'}
                  weight={600}
                >
                  {thirdSectionConfig.lowerSectionTitle}
                </StyledHeading>
                <Box pad={{ horizontal: small ? '0' : '10%' }}>
                  <Text textAlign="center" color={thirdSectionConfig.lowerSectionTextColor} size="1rem">
                    {thirdSectionConfig.lowerSectionText}
                  </Text>
                </Box>
                <Box alignSelf="center">
                  <AppAnchor href="mailto:contact@redflagai.co" target="_blank">
                    <AppButton
                      overrideHover
                      level="dynamicLarge"
                      label={thirdSectionConfig.buttonLabel}
                      alignSelf="center"
                      color={thirdSectionConfig.buttonTextColor}
                      bgColor={thirdSectionConfig.buttonColor}
                    />
                  </AppAnchor>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </FullHeightBox>
  );
};

ContactPage.propTypes = {
  config: PropTypes.shape({
    pageBg: PropTypes.string,
    header: PropTypes.string,
    headerColor: PropTypes.string,
    paragraph: PropTypes.arrayOf(PropTypes.any),
    textColor: PropTypes.string,
    meetingsEmbed: PropTypes.shape({
      bodyScriptSrc: PropTypes.string.isRequired,
      containerClass: PropTypes.string.isRequired,
      containerDataSrc: PropTypes.string.isRequired,
    }),
    formEmbed: PropTypes.shape({
      bodyScriptSrc: PropTypes.string.isRequired,
      region: PropTypes.string.isRequired,
      portalId: PropTypes.string.isRequired,
      formId: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired,
    }),
    embedUrl: PropTypes.string,
    secondSectionConfig: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      sectionTitle: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })).isRequired,
      lowerBannerMessage: PropTypes.string.isRequired,
      lowerBannerBg: PropTypes.string.isRequired,
    }),
    thirdSectionConfig: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      faq: PropTypes.shape({
        sectionBg: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        titleColor: PropTypes.string.isRequired,
        titleIcon: PropTypes.string,
        containerBg: PropTypes.string.isRequired,
        textColor: PropTypes.string.isRequired,
        hoverHighlight: PropTypes.string,
        itemBoxShadow: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({
          question: PropTypes.string.isRequired,
          answer: PropTypes.string.isRequired,
        })).isRequired,
      }).isRequired,
      lowerSectionBg: PropTypes.string.isRequired,
      lowerSectionTitle: PropTypes.string.isRequired,
      lowerSectionText: PropTypes.string.isRequired,
      lowerSectionTextColor: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string.isRequired,
      buttonColor: PropTypes.string.isRequired,
      buttonTextColor: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default ContactPage;
