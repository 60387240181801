import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import {
  Box, AccordionPanel, Image,
} from 'grommet';
import { Add, Subtract } from 'grommet-icons';

import { ScrollerHeader } from './ContentSections';
import { StyledTextContent, StyledFAQAccordion } from './StyledContentSections';


const FAQScroller = ({
  small, title, subTitle, titleColor, titleIcon, containerBg, textColor, hoverHighlight, itemBoxShadow, items,
}) => {
  const [activeIndex, setActiveIndex] = React.useState(null);

  const renderPanelHeader = (header, active) => (
    <Box
      background={containerBg}
      direction="row"
      width="100%"
      align="center"
      pad="1.5rem"
      justify="between"
      round={active ? { size: '6px', corner: 'top' } : '6px'}
    >
      <StyledTextContent className="panelHeader" color={textColor} textAlign="start" weight={600} size={small ? '1.25rem' : '1.15rem'}>
        {header}
      </StyledTextContent>
      {active ? <Subtract color={textColor} size="1.25rem" /> : <Add className="panelOpen" color={textColor} size="1.25rem" />}
    </Box>
  );

  return (
    <Box overflow="hidden">
      <Box direction="column" gap="1rem">
        <Box direction="row" gap="1rem" pad={{ left: titleIcon ? '0.35rem' : '0' }}>
          {titleIcon && (
            <Box
              width={small ? '2rem' : '2.25rem'}
              height={small ? '2rem' : '2.25rem'}
              align="center"
              justify="center"
              pad={{ top: '0.25rem' }}
            >
              <Image src={titleIcon} alt={`${title} icon`} />
            </Box>
          )}
          <ScrollerHeader header={title} color={titleColor} textAlign={titleIcon ? 'start' : 'center'} />
        </Box>
        {subTitle && (
          <Box width="100%" pad={{ top: '0.5rem' }}>
            <StyledTextContent color={titleColor} textAlign="center">
              {subTitle}
            </StyledTextContent>
          </Box>
        )}
        <Box pad={{ top: titleIcon ? '0' : '1.5rem', horizontal: itemBoxShadow ? '0.25rem' : '0' }}>
          <StyledFAQAccordion activeIndex={activeIndex} onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)} hoverHighlight={hoverHighlight} boxShadow={itemBoxShadow}>
            {items.map(({ question, answer }, i) => (
              <AccordionPanel
                key={uniqid()}
                header={renderPanelHeader(question, activeIndex?.includes(i))}
                pad="1.5rem"
              >
                <Box background={containerBg} pad={{ left: '1.5rem', bottom: '1.5rem', right: small ? '1.5rem' : '20%' }} round={{ size: '6px', corner: 'bottom' }}>
                  <StyledTextContent color={textColor} textAlign="start" size={small ? '1rem' : '0.85rem'}>
                    {answer}
                  </StyledTextContent>
                </Box>
              </AccordionPanel>
            ))}
          </StyledFAQAccordion>
        </Box>
      </Box>
    </Box>
  );
};

FAQScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  titleIcon: PropTypes.string,
  subTitle: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  hoverHighlight: PropTypes.string,
  itemBoxShadow: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
  })).isRequired,
};

export default FAQScroller;