import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';

import UseCaseSlider from './UseCaseSlider';
import { ScrollerHeader } from './ContentSections';
import {
  StyledScrollerSubHeader,
} from './StyledContentSections';


const SliderScroller = ({
  small,
  title,
  subTitle = null,
  titleColor,
  slideTextColor,
  slideBoxShadow,
  slideBg,
  highlight,
  slides,
}) => (
  <Box justify="center" overflow="hidden">
    <Box direction="column" gap="2rem">
      <Box
        align="center"
        justify="center"
        gap="2rem"
        pad={{ horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)' }}
      >
        <ScrollerHeader header={title} color={titleColor} alignSelf="center" />
        {subTitle && (
          <Box width={small ? null : { max: '65%' }}>
            <StyledScrollerSubHeader
              color={titleColor}
              margin="none"
              size="1.25rem"
              textAlign="center"
            >
              {subTitle}
            </StyledScrollerSubHeader>
          </Box>
        )}
      </Box>
      <UseCaseSlider
        small={small}
        content={slides}
        textColor={slideTextColor}
        boxShadow={slideBoxShadow}
        highlight={highlight}
        slideBg={slideBg}
      />
    </Box>
  </Box>
);

SliderScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  slideTextColor: PropTypes.string.isRequired,
  slideBoxShadow: PropTypes.string.isRequired,
  slideBg: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(PropTypes.objectOf({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
};

export default SliderScroller;
