import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box } from 'grommet';

import { LazyResponsiveImageLoader } from '@/components/Control';
import { optimizeImageSrc } from '@/helpers/util';
import {
  StyledSectionHeader,
  StyledTextContent,
  StyledScrollerSubHeader,
} from './StyledContentSections';


const InfoRowScroller = ({
  small,
  header,
  subHeader = null,
  headerColor,
  listTitleColor,
  listTextColor,
  listIcon = null,
  items,
  numbered = false,
  numberBg = null,
}) => {
  const rowWidth = (items.length > 3) ? `calc(100%/${items.length / 2})` : `calc(100%/${items.length})`;
  const minHeight = (items.length > 3) ? '5rem' : '10rem';
  const verticalMargin = (items.length > 3) ? '1rem' : '0';
  const renderListItem = (title, text, index) => (
    <Box
      flex={numbered}
      key={uniqid()}
      direction="row"
      justify={(numbered && !small) ? 'center' : 'start'}
      align="start"
      gap="0.75rem"
      height={small ? 'auto' : { min: minHeight }}
      width={small ? '100%' : { max: rowWidth, min: rowWidth }}
      margin={{ vertical: verticalMargin }}
    >
      {listIcon && (
        <Box width={small ? '1.35rem' : '1.25rem'} height={small ? '1.35rem' : '1.25rem'}>
          <LazyResponsiveImageLoader
            src={() => (small ? `${optimizeImageSrc(listIcon)}&w=350` : optimizeImageSrc(listIcon))}
            alt={`${title} icon`}
            fit="contain"
          />
        </Box>
      )}
      {numbered && (
        <Box
          width={small ? '1.5rem' : '1.35rem'}
          height={small ? '1.5rem' : '1.35rem'}
          background={numberBg}
          align="center"
          justify="center"
          round={small ? '3px' : '6px'}
        >
          <StyledTextContent color="white" textAlign="center">{index + 1}</StyledTextContent>
        </Box>
      )}
      <Box direction="column" gap="0.5rem" width={!small ? '70%' : '100%'}>
        <StyledScrollerSubHeader level={4} color={listTitleColor} weight={600} textAlign="start" size={small ? '1.35rem' : '1.15rem'} margin="none">
          {title}
        </StyledScrollerSubHeader>
        <StyledTextContent color={listTextColor} textAlign="start">
          {text}
        </StyledTextContent>
      </Box>
    </Box>
  );

  return (
    <Box overflow="hidden">
      <Box
        flex
        direction="column"
        width="100%"
        gap="3rem"
      >
        <Box flex gap="1rem" justify="center" align="center">
          <StyledSectionHeader level={2} weight={600} size="2rem" margin="none" color={headerColor} textAlign="center">
            {header}
          </StyledSectionHeader>
          <Box direction="column" gap="0.5rem">
            {subHeader && subHeader.map((dt, i) => (
              <StyledScrollerSubHeader
                key={uniqid()}
                level={3}
                color={headerColor}
                margin="none"
                size={small ? '1.15rem' : '1rem'}
                weight={(i === subHeader.length - 1) ? 600 : 'normal'}
                textAlign="center"
              >
                {dt}
              </StyledScrollerSubHeader>
            ))}
          </Box>
        </Box>
        <Box
          wrap
          direction={small ? 'column' : 'row'}
          gap={small ? '2rem' : '0rem'}
          width="100%"
          justify={small ? 'center' : 'start'}
        >
          {items.map(({ title, text }, i) => (
            renderListItem(title, text, i)
          ))}
        </Box>
      </Box>
    </Box>
  );
};

InfoRowScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.arrayOf(PropTypes.string.isRequired),
  headerColor: PropTypes.string.isRequired,
  listTitleColor: PropTypes.string.isRequired,
  listTextColor: PropTypes.string.isRequired,
  listIcon: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  numbered: PropTypes.bool,
  numberBg: PropTypes.string,
};

export default InfoRowScroller;
