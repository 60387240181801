import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box } from 'grommet';
import { optimizeImageSrc } from '@/helpers/util';
import { ScrollerHeader } from './ContentSections';
import {
  StyledTextContent,
  StyledSmallHeader,
  StyledSVG,
  StyledIconCardsWrapper,
} from './StyledContentSections';


const IconCardsScroller = ({
  small, title, titleColor, titleCardBg, cardTextColor, cardBgColor, items,
}) => {
  const renderCard = (icon, header, text) => (
    <StyledIconCardsWrapper key={uniqid()} width={small ? '100%' : '33%'}>
      <Box flex background={cardBgColor} round="6px" direction="column" gap={small ? '0.75rem' : '0.65rem'}  pad="1.5rem">
        <StyledSVG src={optimizeImageSrc(icon)} height="2.5rem" width="2.5rem" />
        <Box direction="column" gap={small ? '0.75rem' : '0.65rem'}>
          <StyledSmallHeader color={cardTextColor} weight={600} size="1.25rem">
            {header}
          </StyledSmallHeader>
          <StyledTextContent color={cardTextColor}>
            {text}
          </StyledTextContent>
        </Box>
      </Box>
    </StyledIconCardsWrapper>
  );

  return (
    <Box overflow="hidden" pad={{ right: small ? 'var(--vw5p725)' : 'var(--vw7p725)', left: small ? 'var(--vw5p725)' : '0' }} direction="column" gap="2rem">
      <Box direction={small ? 'column' : 'row'} gap="1.5rem">
        <StyledIconCardsWrapper
          background={titleCardBg}
          align="center"
          justify="center"
          pad={{ vertical: '1.5rem', left: small ? 'var(--vw5p725)' : 'var(--vw7p725)', right: '1.5rem' }} 
          useHeight={small ? '10rem' : null}
          width={small ? '100%' : 'calc(33% + var(--vw7p725))'}
        >
          <Box pad={{ horizontal: '1.5rem' }}>
            <ScrollerHeader header={title} color={titleColor} textAlign="start" />
          </Box>
        </StyledIconCardsWrapper>
        {items.slice(0, 2).map(({ icon, header, text }) => (renderCard(icon, header, text)))}
      </Box>
      <Box direction={small ? 'column' : 'row'} gap="1.5rem" pad={{ left: small ? '0' : 'var(--vw7p725)' }} justify={small ? 'center' : 'end'}>
        {items.slice(2, 5).map(({ icon, header, text }) => (renderCard(icon, header, text)))}
      </Box>
    </Box>
  );
};

IconCardsScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  titleCardBg: PropTypes.string.isRequired,
  cardTextColor: PropTypes.string.isRequired,
  cardBgColor: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};

export default IconCardsScroller;
