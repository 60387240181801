import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import Marquee from 'react-fast-marquee';

import { Box, Text } from 'grommet';


const MarqueeTextScroller = ({
  small, textColor, items,
}) => (
  <Box overflow="hidden">
    <Marquee pauseOnHover autoFill>
      <Box wrap="false" direction="row" gap="2.5rem" pad={{ left: '2.5rem', vertical: small ? '0.75rem' : '0.5rem' }}>
        {items.map((dt) => (
          <Text key={uniqid()} uppercase size={small ? '12px' : '14px'} weight={600} color={textColor} style={{ letterSpacing: '2.4px' }}>{dt}</Text>
        ))}
      </Box>
    </Marquee>
  </Box>
);

MarqueeTextScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default MarqueeTextScroller;
