'use client';

import React from 'react';
import PropTypes from 'prop-types';

import { useClientMediaQuery } from '@/lib/clientMediaQuery';
import HeadSection from './HeadSection';
import ContentSections from './ContentSections';


const ServiceDynamicPage = ({
  uid,
  config = null,
  industries = null,
  articles = null,
}) => {
  const small = useClientMediaQuery('(max-width: 768px)');

  if (config && uid) {
    const { scrollers, ...rest } = config[uid];

    return (
      <>
        <HeadSection
          key={uid}
          small={small}
          {...rest}
        />
        <ContentSections
          small={small}
          scrollers={scrollers}
          industries={industries}
          articles={articles}
        />
      </>
    );
  }

  return null;
};

ServiceDynamicPage.propTypes = {
  industries: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
  })),
  articles: PropTypes.arrayOf(PropTypes.any),
  uid: PropTypes.string.isRequired,
  config: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string,
    preHeader: PropTypes.string,
    preHeaderColor: PropTypes.string,
    fullHeightHeadSection: PropTypes.bool,
    header: PropTypes.string.isRequired,
    desktopHeader: PropTypes.string,
    desktopHeaderLogo: PropTypes.string,
    subHeader: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    headerColor: PropTypes.string.isRequired,
    headersBg: PropTypes.string,
    image: PropTypes.string,
    fullPageBgImage: PropTypes.string,
    fullPageBgImageMobile: PropTypes.string,
    customBgColor: PropTypes.string,
    headerApproach: PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      hoverColor: PropTypes.string.isRequired,
    }),
    scrollers: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired),
};

export default ServiceDynamicPage;
