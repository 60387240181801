'use client';

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from 'grommet';
import { Player, BigPlayButton } from 'video-react';

import 'video-react/dist/video-react.css';

import { optimizeImageSrc } from '@/helpers/util';
import { ScrollerHeader } from './ContentSections';
import {
  StyledAspectScrollerDemo,
  StyledScrollerSubHeader,
} from './StyledContentSections';

const StyledPlayer = styled(Player)`
  .video-react-paused {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .video-react-poster {
    border-radius: 10px;
    background-size: cover;
  }

  .video-react-big-play-button {
    height: ${({ isSmall }) => (isSmall ? '40px' : '82px')};
    width: ${({ isSmall }) => (isSmall ? '60px' : '120px')};
    border: none;
    background-color: ${({ buttonColor }) => buttonColor};
    border-radius: 10px;
    margin-top: ${({ isSmall }) => (isSmall ? '-0.75em' : '-1.75em')} !important;

    &:before {
      padding-top: ${({ isSmall }) => (isSmall ? '0' : '20px')};
      transform: ${({ isSmall }) => (isSmall ? 'scale(1.2)' : 'scale(1.5)')};
    }
  }

  &:hover {
    .video-react-big-play-button {
      background: linear-gradient(#0000, rgb(0 0 0 / 40%)) top / 100% 800%;
      background-color: ${({ buttonColor }) => buttonColor};
    }
  }
`;

const VideoScroller = ({
  small,
  header,
  headerColor,
  subHeader = null,
  headerAlign = null,
  textColor = null,
  buttonHighlight,
  loaderImage,
  layout,
  animation,
}) => {
  const videoSrc = Object.keys(animation).reduce((result, key) => {
    result[key] = animation[key];
    return result;
  }, {});

  return (
    <Box overflow="hidden">
      <Box flex direction={layout} align="center" gap={small ? '1.5rem' : '2rem'}>
        <Box direction="column" gap="1.5rem" width={small ? '100%' : 'var(--vw50)'}>
          <ScrollerHeader header={header} color={headerColor} textAlign={headerAlign || 'start'} />
          {subHeader && (
            <StyledScrollerSubHeader
              level={3}
              color={textColor}
              margin="none"
              size="1rem"
              textAlign={headerAlign || 'start'}
            >
              {subHeader}
            </StyledScrollerSubHeader>
          )}
        </Box>
        <StyledAspectScrollerDemo overflow="hidden" round="10px" height="auto" width={small ? '100%' : 'var(--vw50)'}>
          <StyledPlayer
            playsInline
            fluid
            isSmall={small}
            poster={loaderImage}
            buttonColor={buttonHighlight}
          >
            <source src={optimizeImageSrc(videoSrc.mp4)} type="video/mp4" />
            <source src={optimizeImageSrc(videoSrc.webm)} type="video/webm" />
            <Box className="loaderOverlay" width="100%" height="100%" />
            <BigPlayButton position="center" />
          </StyledPlayer>
        </StyledAspectScrollerDemo>
      </Box>
    </Box>
  );
};

VideoScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  headerColor: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
  headerAlign: PropTypes.string,
  textColor: PropTypes.string,
  loaderImage: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  animation: PropTypes.shape({
    mp4: PropTypes.string.isRequired,
    webm: PropTypes.string.isRequired,
  }).isRequired,
};

export default VideoScroller;
