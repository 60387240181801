'use client';

import React from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';

import { useClientMediaQuery } from '@/lib/clientMediaQuery';
import HeadSection from '../ServiceDynamic/HeadSection';
import ContentSections from '../ServiceDynamic/ContentSections';


const AboutPage = ({ aboutPageConfig = null }) => {
  const small = useClientMediaQuery('(max-width: 768px)');

  if (aboutPageConfig) {
    const {
      image,
      preHeader,
      header,
      fullHeightHeadSection,
      desktopHeader,
      desktopHeaderLogo,
      subHeader,
      headerColor,
      headerApproach,
      highlightColor,
      customBgColor,
      scrollers,
      widgetScriptUrl,
    } = aboutPageConfig;

    return (
      <>
        <Script src={widgetScriptUrl} strategy="afterInteractive" />
        <HeadSection
          small={small}
          fullHeightHeadSection={fullHeightHeadSection}
          image={image}
          preHeader={preHeader}
          header={header}
          desktopHeader={desktopHeader}
          desktopHeaderLogo={desktopHeaderLogo}
          subHeader={subHeader}
          headerColor={headerColor}
          headerApproach={headerApproach}
          highlightColor={highlightColor}
          customBgColor={customBgColor}
        />
        <ContentSections
          small={small}
          highlightColor={highlightColor}
          scrollers={scrollers}
        />
      </>
    );
  }

  return null;
};

AboutPage.propTypes = {
  aboutPageConfig: PropTypes.shape({
    fullHeightHeadSection: PropTypes.bool,
    image: PropTypes.string,
    preHeader: PropTypes.string,
    header: PropTypes.string.isRequired,
    desktopHeader: PropTypes.string,
    desktopHeaderLogo: PropTypes.string,
    subHeader: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    headerColor: PropTypes.string.isRequired,
    headerApproach: PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      hoverColor: PropTypes.string.isRequired,
    }),
    highlightColor: PropTypes.string.isRequired,
    customBgColor: PropTypes.string.isRequired,
    widgetScriptUrl: PropTypes.string.isRequired,
    scrollers: PropTypes.objectOf(PropTypes.any).isRequired,
  }),
};

export default AboutPage;
