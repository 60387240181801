import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box, Image } from 'grommet';

import { optimizeImageSrc } from '@/helpers/util';
import {
  StyledSectionHeader,
  StyledTextContent,
  StyledScrollerSubHeader,
  StyledFlexGrowContainer,
} from './StyledContentSections';

const ListBoxContents = ({
  small,
  config: {
    textColor,
    listIcon,
    header,
    items,
  },
}) => (
  <Box
    flex
    direction="column"
    justify="start"
    align="start"
    gap="0.5rem"
  >
    <StyledScrollerSubHeader level={4} color={textColor} weight={600} textAlign="start" size={small ? '1.35rem' : '1.25rem'} margin="none">
      {header}
    </StyledScrollerSubHeader>
    <Box wrap direction="row">
      {items && items.map(({ title, text }) => (
        <Box key={uniqid()} direction="row" gap="0.35rem" width={small ? '100%' : '50%'} margin={{ vertical: '1rem' }} pad={{ right: small ? '0' : '2.5rem' }}>
          <Box width={{ min: small ? '1.15rem' : '1rem', max: small ? '1.15rem' : '1rem' }} height={{ min: small ? '1.15rem' : '1rem', max: small ? '1.15rem' : '1rem' }}>
            <Image
              src={optimizeImageSrc(listIcon)}
              alt={`${title} icon`}
              fit="contain"
              height="100%"
            />
          </Box>
          <Box direction="column" gap="0.35rem">
            <StyledScrollerSubHeader level={5} color={textColor} weight={600} textAlign="start" size={small ? '1.15rem' : '1rem'} margin="none">
              {title}
            </StyledScrollerSubHeader>
            {text && (
              <StyledTextContent color={textColor} textAlign="start">
                {text}
              </StyledTextContent>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  </Box>
);

ListBoxContents.propTypes = {
  small: PropTypes.bool.isRequired,
  config: PropTypes.shape({
    textColor: PropTypes.string.isRequired,
    listIcon: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string,
    })).isRequired,
  }).isRequired,
};

const ListCardsScroller = ({
  small,
  header = null,
  headerImage = null,
  subHeader = null,
  headerColor,
  firstListConfig,
  secondListConfig,
}) => (
  <Box overflow="hidden">
    <Box
      flex
      direction="column"
      width="100%"
      gap="2.5rem"
    >
      <Box flex gap="1rem" justify="center" align="center">
        {header && (
          <StyledSectionHeader level={2} weight={600} size="2rem" margin="none" color={headerColor}>
            {header}
          </StyledSectionHeader>
        )}
        {headerImage && (
          <Box align="center" height="100%" width={{ max: '20rem' }}>
            <Image
              fit="contain"
              src={optimizeImageSrc(headerImage)}
              alt="Redflag AI logo"
              height="100%"
              width={{ max: '20rem' }}
            />
          </Box>
        )}
        {subHeader && (
          <StyledScrollerSubHeader
            key={uniqid()}
            level={3}
            color={headerColor}
            margin="none"
            size={small ? '1.15rem' : '1rem'}
            weight={600}
            textAlign={small ? 'start' : 'center'}
          >
            {subHeader}
          </StyledScrollerSubHeader>
        )}
      </Box>
      <Box flex direction={small ? 'column' : 'row'} gap="1.5rem">
        <StyledFlexGrowContainer
          flexGrow={1}
          round="10px"
          pad="1.5rem"
          margin="0.5rem"
          background={firstListConfig.boxBg}
          boxShadow={firstListConfig.boxShadow}
          boxBorder={firstListConfig.border}
        >
          <ListBoxContents small={small} config={firstListConfig} />
        </StyledFlexGrowContainer>
        <StyledFlexGrowContainer
          flexGrow={1}
          round="10px"
          pad="1.5rem"
          margin="0.5rem"
          background={secondListConfig.boxBg}
          boxShadow={secondListConfig.boxShadow}
          boxBorder={secondListConfig.border}
        >
          <ListBoxContents small={small} config={secondListConfig} />
        </StyledFlexGrowContainer>
      </Box>
    </Box>
  </Box>
);

ListCardsScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  header: PropTypes.string,
  headerImage: PropTypes.string,
  subHeader: PropTypes.string,
  headerColor: PropTypes.string.isRequired,
  firstListConfig: PropTypes.shape({
    boxBg: PropTypes.string.isRequired,
    boxShadow: PropTypes.string,
    border: PropTypes.string,
    textColor: PropTypes.string.isRequired,
    listIcon: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string,
    })).isRequired,
  }).isRequired,
  secondListConfig: PropTypes.shape({
    boxBg: PropTypes.string.isRequired,
    boxShadow: PropTypes.string,
    border: PropTypes.string,
    textColor: PropTypes.string.isRequired,
    listIcon: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string,
    })).isRequired,
  }).isRequired,
};

export default ListCardsScroller;
