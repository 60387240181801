import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box, Image } from 'grommet';

import { optimizeImageSrc } from '@/helpers/util';
import { ScrollerHeader } from './ContentSections';
import {
  StyledTechStackContainer,
  StyledScrollerSubHeader,
  StyledTextContent,
} from './StyledContentSections';


const TechStackScroller = ({
  small,
  header,
  headerColor,
  listIcon,
  boxShadow,
  cardBg,
  cardTitleColor,
  cardTextColor,
  items,
}) => (
  <Box overflow="hidden">
    <Box>
      <Box pad={{ bottom: '1.5rem' }}>
        <ScrollerHeader header={header} color={headerColor} />
      </Box>
      <Box flex justify={small ? 'start' : 'center'} align={small ? 'start' : 'center'}>
        <Box wrap width="100%" direction={small ? 'column' : 'row'} justify="center">
          {items.map(({
            title, icon, text, listItems,
          }) => (
            <StyledTechStackContainer
              key={uniqid()}
              direction="column"
              round="10px"
              width={small ? '95%' : '24rem'}
              height={{ min: '20rem' }}
              background={cardBg}
              gap="1rem"
              pad="1.5rem"
              margin={small ? '1rem' : '1.5rem'}
              boxShadow={boxShadow}
            >
              <Box
                direction="row"
                justify="start"
                align="center"
                gap="1rem"
              >
                <Box width="3rem" height="3rem">
                  <Image
                    src={small ? `${optimizeImageSrc(icon)}&w=350` : optimizeImageSrc(icon)}
                    alt={`${title} icon`}
                    fit="contain"
                    width="3rem"
                    height="3rem"
                  />
                </Box>
                <Box justify="center">
                  <StyledScrollerSubHeader color={cardTitleColor} size={small ? '1.4rem' : '1.2rem'} weight={600} margin="none">
                    {title}
                  </StyledScrollerSubHeader>
                </Box>
              </Box>
              <Box
                flex
                direction="column"
                fill="horizontal"
                gap="1rem"
              >
                <Box
                  pad={{ vertical: '1rem' }}
                  border={{
                    side: 'bottom',
                    size: '1px',
                    style: 'solid',
                    color: '#D0D0D0',
                  }}
                >
                  <StyledTextContent isSmall={small} color={cardTextColor}>
                    {text}
                  </StyledTextContent>
                </Box>
                {listItems.map((li) => (
                  <Box key={uniqid()} direction="row" gap="0.5rem" align="center">
                    <Box
                      width={{ min: small ? '1.15rem' : '1rem', max: small ? '1.15rem' : '1rem' }}
                      height={{ min: small ? '1.15rem' : '1rem', max: small ? '1.15rem' : '1rem' }}
                    >
                      <Image
                        src={small ? `${optimizeImageSrc(listIcon)}&w=350` : optimizeImageSrc(listIcon)}
                        alt="List icon"
                        fit="contain"
                        height="100%"
                      />
                    </Box>
                    <StyledTextContent isSmall={small} color={cardTextColor} weight={600}>
                      {li}
                    </StyledTextContent>
                  </Box>
                ))}
              </Box>
            </StyledTechStackContainer>
          ))}
        </Box>
      </Box>
    </Box>
  </Box>
);

TechStackScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  headerColor: PropTypes.string.isRequired,
  listIcon: PropTypes.string.isRequired,
  cardBg: PropTypes.string.isRequired,
  cardTitleColor: PropTypes.string.isRequired,
  cardTextColor: PropTypes.string.isRequired,
  boxShadow: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    listItems: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  })).isRequired,
};

export default TechStackScroller;
