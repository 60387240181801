import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box } from 'grommet';

import { LazyResponsiveImageLoader } from '@/components/Control';
import { optimizeImageSrc } from '@/helpers/util';
import {
  StyledSectionHeader,
  StyledTextContent,
  StyledScrollerSubHeader,
  StyledCardImage,
} from './StyledContentSections';


const IllustrationScroller = ({
  small,
  header,
  subHeader = null,
  headerColor,
  listTitleColor,
  listTextColor,
  listIcon = null,
  illustration,
  firstColumn,
  secondColumn = null,
}) => {
  const listItemHeight = secondColumn ? '7rem' : '5.5rem';

  const renderListItem = (title, text) => (
    <Box
      key={uniqid()}
      direction="row"
      justify="start"
      align="start"
      gap="0.75rem"
      height={small ? 'auto' : { min: listItemHeight }}
    >
      {listIcon && (
        <Box width={small ? '1.35rem' : '1.25rem'} height={small ? '1.35rem' : '1.25rem'}>
          <LazyResponsiveImageLoader
            src={() => (small ? `${optimizeImageSrc(listIcon)}&w=350` : optimizeImageSrc(listIcon))}
            alt={`${title} icon`}
            fit="contain"
          />
        </Box>
      )}
      <Box direction="column" gap="0.5rem" width={(secondColumn && !small) ? '70%' : '100%'}>
        <StyledScrollerSubHeader level={4} color={listTitleColor} weight={700} textAlign="start" size={small ? '1.5rem' : '1.25rem'} margin="none">
          {title}
        </StyledScrollerSubHeader>
        <StyledTextContent color={listTextColor} textAlign="start">
          {text}
        </StyledTextContent>
      </Box>
    </Box>
  );

  return (
    <Box overflow="hidden">
      <Box direction="row">
        <Box
          flex
          direction="column"
          gap="3rem"
          width="100%"
          justify={small ? 'center' : 'start'}
          align={small ? 'center' : 'start'}
          pad={{ bottom: small ? '2.5rem' : '3rem' }}
        >
          <Box gap="1rem">
            <StyledSectionHeader level={2} weight={600} size="2rem" margin="none" color={headerColor} textAlign="start">
              {header}
            </StyledSectionHeader>
            <StyledScrollerSubHeader level={3} color={headerColor} margin="none" size="1.25rem">
              {subHeader}
            </StyledScrollerSubHeader>
          </Box>
          {small && (
            <StyledCardImage
              data-bg={illustration}
              background={{ image: small ? `url(${illustration}&w=1024)` : `url(${illustration})` }}
              overflow="hidden"
              height="22rem"
              width="100%"
              bgSize="80%"
            />
          )}
          <Box
            direction="row"
            gap="1rem"
            width="100%"
            pad={{ right: ((secondColumn || small) ? '0rem' : '5rem') }}
            justify={small ? 'center' : 'start'}
          >
            <Box direction={(secondColumn && !small) ? 'row' : 'column'} gap={small ? '2rem' : '1.5rem'} align="start">
              <Box direction="column" gap={small ? '2rem' : '1rem'}>
                {firstColumn.map(({ title, text }) => (
                  renderListItem(title, text)
                ))}
              </Box>
              {secondColumn && (
                <Box direction="column" gap={small ? '2rem' : '1rem'}>
                  {secondColumn.map(({ title, text }) => (
                    renderListItem(title, text)
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {!small && (
          <Box flex margin={{ vertical: '1rem' }}>
            <StyledCardImage
              data-bg={illustration}
              background={{ image: small ? `url(${illustration}&w=1024)` : `url(${illustration})` }}
              overflow="hidden"
              height="100%"
              width="100%"
              bgSize="95%"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

IllustrationScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
  headerColor: PropTypes.string.isRequired,
  listTitleColor: PropTypes.string.isRequired,
  listTextColor: PropTypes.string.isRequired,
  listIcon: PropTypes.string,
  illustration: PropTypes.string.isRequired,
  firstColumn: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  secondColumn: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
};

export default IllustrationScroller;
