'use client';

import React from 'react';
import PropTypes from 'prop-types';

import uniqid from 'uniqid';

import { Box } from 'grommet';

import { Container } from '@/components/Control';
import { sdgSolutions } from '@/components/configs';

import {
  StyledHeader,
  StyledStrong,
  StyledLogo,
  StyledIconTitle,
  StyledIcon,
  StyledIconDescription,
  StyledContactTitle,
  StyledContactAnchor,
} from './StyledSdgPage';

const Section = ({
  top = false,
  children,
  childWidth = null,
  childAlign = 'start',
  ...rest
}) => (
  <Box overflow="hidden" justify="center" background="white" {...rest}>
    <Container pad={{ vertical: 'xlarge', horizontal: 'var(--vw10)' }} align="center">
      <Box
        top={top}
        width={childWidth}
        align={childAlign}
        pad={{ vertical: 'xlarge' }}
        gap="large"
      >
        {children}
      </Box>
    </Container>
  </Box>
);

Section.propTypes = {
  top: PropTypes.bool,
  children: PropTypes.node.isRequired,
  childWidth: PropTypes.string,
  childAlign: PropTypes.string,
};

const SdgGoalContainer = ({ small, config }) => {
  const {
    title,
    subtitle,
    text,
    icon,
    bgColor,
    reverse,
  } = config;
  const isReverse = !small && reverse;
  const direction = small ? 'column' : `row${isReverse ? '-reverse' : ''}`;
  const goalAlign = isReverse ? 'end' : 'start';

  return (
    <Section background={bgColor} childWidth="63rem">
      <StyledIconTitle alignSelf={goalAlign} textAlign={goalAlign} level={2} size="small">
        <StyledStrong>{title}</StyledStrong>
        {subtitle}
      </StyledIconTitle>
      <Box direction={direction} gap="large">
        <StyledIcon src={icon} alt={`SDG ${title} icon`} fit="contain" />
        <Box flex>
          <StyledIconDescription size="xlarge" margin="none" textAlign={goalAlign}>
            {text}
          </StyledIconDescription>
        </Box>
      </Box>
    </Section>
  );
};

SdgGoalContainer.propTypes = {
  small: PropTypes.bool.isRequired,
  config: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
    bgColor: PropTypes.string,
    reverse: PropTypes.bool,
  }).isRequired,
};

const SdgPage = ({ small }) => (
  <Box background="white" margin={{ top: '5.2rem' }}>
    <Section top childWidth="43.75rem" childAlign="center">
      <StyledHeader textAlign="center" size="2rem">
        <StyledStrong inline>Redflag&apos;s&nbsp;</StyledStrong>
        participation in the 2030 Agenda for Sustainable Development
      </StyledHeader>
      <StyledLogo
        src={sdgSolutions.logo}
        alt="SDG logo"
        fit="contain"
      />
    </Section>
    {sdgSolutions.goal
      .map((config) => <SdgGoalContainer key={uniqid()} small={small} config={config} />)}
    <Section background="#f7fcff" childAlign="center">
      <StyledContactTitle
        uppercase
        level={3}
        size="small"
        margin="none"
        textAlign="center"
      >
        Partner with us
      </StyledContactTitle>
      <StyledContactAnchor href="mailto:sdg@redflagai.co" label="sdg@redflagai.co" />
    </Section>
  </Box>
);

SdgPage.propTypes = {
  small: PropTypes.bool.isRequired,
};

export default SdgPage;
