import React from 'react';
import PropTypes from 'prop-types';

import Link from 'next/link';
import { Text, Box } from 'grommet';
import { AppButton } from '@/components/Control';

import { StyledScrollerHeader, StyledBambooWidget } from './StyledContentSections';


const CareersScroller = ({
  small,
  header,
  headerColor,
  headerBannerBg,
  primaryTextColor,
  highlightColor,
}) => (
  <Box overflow="hidden">
    <Box background={{ image: small ? `url(${headerBannerBg}&w=1024)` : `url(${headerBannerBg})` }} pad={{ vertical: '2.5rem' }}>
      <StyledScrollerHeader textAlign="center" level={2} color={headerColor} margin="none">
        {header}
      </StyledScrollerHeader>
    </Box>
    <Box pad={{ horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)', bottom: '3.75rem', top: '1.5rem' }}>
      <Box pad={{ vertical: '2rem' }}>
        <StyledBambooWidget
          id="BambooHR"
          data-domain="redflag.bamboohr.com"
          data-version="1.0.0"
          data-departmentid=""
          data-nopudgy
          color={primaryTextColor}
          $highlight={highlightColor}
        />
      </Box>
      <Box align="center" pad={{ bottom: '2rem' }}>
        <Link target="_blank" href="https://redflag.bamboohr.com/careers" style={{ textDecoration: 'none' }}>
          <AppButton overrideHover level="dynamicLarge" color="white" bgColor={highlightColor} label="Learn More" fontWeight={600} />
        </Link>
      </Box>
      <Text size="0.75rem" color={primaryTextColor} textAlign="start">
        As an equal opportunity employer, Redflag AI Inc., is committed to a
        diverse workforce. If you are a qualified individual with a disability
        or a disabled veteran, you have the right to request a reasonable
        accommodation if you are unable or limited in your ability to use or
        access Redflag AI Inc.&apos;s career website as  result of your disability.
        You may request reasonable accommodations by reaching out at&nbsp;
        <a style={{ color: highlightColor }} href="mailto:contact@redflagai.co">contact@redflagai.co</a>
        .
        <br />
        <br />
        PAY TRANSPARENCY NONDISCRIMINATION PROVISION:
        The contractor will not discharge or in any other manner discriminate against
        employees or applicants because they have inquired about, discussed,
        or disclosed their own pay or the pay of another employee or applicant.
        However, employees who have access to the compensation information of
        other employees or applicants as a part of their essential job functions
        cannot disclose the pay of other employees or applicants to individuals
        who do not otherwise have access to compensation information,
        unless the disclosure is (a) in response to a formal complaint or charge,
        (b) in furtherance of an investigation, proceeding, hearing, or action,
        including an investigation conducted by the employer, or (c) consistent
        with the contractor&apos;s legal duty to furnish information. 41 CFR 60-1.35(c)
      </Text>
    </Box>
  </Box>
);

CareersScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  headerColor: PropTypes.string.isRequired,
  headerBannerBg: PropTypes.string.isRequired,
  primaryTextColor: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
};

export default CareersScroller;
