import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box, Carousel, Text } from 'grommet';

import { optimizeImageSrc } from '@/helpers/util';
import { AppAnchor, AppButton } from '@/components/Control';
import { StyledHeader } from '../ServiceDynamic/HeadSection/StyledHeadSection';
import {
  StyledTableauStudiesIllustrationWrapper,
  StyledPreHeading,
  StyledPreHeadingContainer,
} from './StyledTableauStudiesPage';


const SlidesComponent = ({ small, carouselImages }) => (
  <Carousel
    wrap
    fill
    play={3500}
  >
    {carouselImages.map((image) => (
      <Box
        fill
        key={uniqid()}
        align="center"
        justify="center"
        pad="2.5rem"
      >
        <Box
          fill
          data-bg={image}
          background={{
            color: 'rgba(0,0,0,0)',
            image: small ? `url(${optimizeImageSrc(image)}&w=1024)` : `url(${optimizeImageSrc(image)})`,
            repeat: 'no-repeat',
            size: 'contain',
          }}
        />
      </Box>
    ))}
  </Carousel>
);

SlidesComponent.propTypes = {
  small: PropTypes.bool.isRequired,
  carouselImages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

const HeadSection = ({
  small,
  smallerHeader = false,
  carouselImages,
  fullScreenBg = null,
  preHeader = null,
  header,
  subHeader = null,
  disclaimer = null,
  headersColor,
  headerApproach = null,
  highlightColor,
  customBgColor = null,
}) => {
  const alignContent = small ? 'center' : 'start';

  return (
    <Box overflow="hidden" justify="center" background={customBgColor || highlightColor}>
      <Box
        fill
        justify="center"
        background={fullScreenBg && {
          image: `url(${optimizeImageSrc(fullScreenBg)})`,
          position: 'right 0px',
        }}
      >
        <StyledTableauStudiesIllustrationWrapper
          fill
          background={{
            color: fullScreenBg ? 'rgba(0,0,0,0)' : (customBgColor || highlightColor),
          }}
          pad={{ top: '2.5rem', horizontal: small ? '2.55rem' : '3vw' }}
          justify={small ? null : 'center'}
        >
          <Box justify="center">
            <Box pad={small ? { top: '1rem', bottom: 'calc(40px + var(--vw4))' } : '0rem'}>
              <Box direction={small ? 'column' : 'row'} justify="between">
                <Box
                  width={small ? null : '50%'}
                  pad={{ bottom: '6.5rem', top: small ? '6.5rem' : '0rem' }}
                  justify="center"
                >
                  {preHeader && (
                    <Box width="100%" align={small ? 'center' : 'start'}>
                      <StyledPreHeadingContainer background={highlightColor} margin={{ bottom: '1rem' }}>
                        <StyledPreHeading
                          uppercase
                          level={3}
                          color="white"
                          margin="none"
                          textAlign="center"
                        >
                          {preHeader}
                        </StyledPreHeading>
                      </StyledPreHeadingContainer>
                    </Box>
                  )}
                  <Box gap="2rem" pad={{ right: small ? '0rem' : '2.5rem' }}>
                    <StyledHeader smallerHeader={smallerHeader} level={1} margin="none" color={headersColor} weight={800} textAlign={alignContent}>
                      {header}
                    </StyledHeader>
                    {subHeader && (
                      <Box width={small ? '100%' : { max: '28rem' }} pad={small ? { horizontal: '1.75rem' } : null}>
                        <StyledHeader level={2} color={headersColor} margin="none" textAlign={alignContent}>
                          {subHeader}
                        </StyledHeader>
                      </Box>
                    )}
                  </Box>
                  {headerApproach && (
                    <Box pad={{ top: '0.5rem' }} align={small ? 'center' : 'start'}>
                      <AppAnchor path={headerApproach.path}>
                        <AppButton
                          overrideHover
                          level="dynamicLarge"
                          bgColor={headerApproach.color}
                          color={headerApproach.hoverColor}
                          label={headerApproach.text}
                          fontWeight={600}
                        />
                      </AppAnchor>
                    </Box>
                  )}
                </Box>
                <Box width={small ? '100%' : '50%'} height="30rem" pad={{ bottom: small ? '0rem' : '4rem' }}>
                  <SlidesComponent small={small} carouselImages={carouselImages} />
                  {disclaimer && (
                    <Text size="0.75rem" color="#777777" textAlign="center">{disclaimer}</Text>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledTableauStudiesIllustrationWrapper>
      </Box>
    </Box>
  );
};

HeadSection.propTypes = {
  small: PropTypes.bool.isRequired,
  smallerHeader: PropTypes.bool,
  carouselImages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  fullScreenBg: PropTypes.string,
  disclaimer: PropTypes.string,
  preHeader: PropTypes.string,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
  headersColor: PropTypes.string.isRequired,
  headerApproach: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }),
  highlightColor: PropTypes.string.isRequired,
  customBgColor: PropTypes.string,
};

export default HeadSection;
