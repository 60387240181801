'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { Markup } from 'interweave';
import _ from 'lodash';
import uniqid from 'uniqid';

import Link from 'next/link';
import {
  Box, Text, Image, Heading, Button,
} from 'grommet';
import { Linkedin } from 'grommet-icons';

import { useClientMediaQuery } from '@/lib/clientMediaQuery';
import { optimizeImageSrc, fixedEncodeURIComponent } from '@/helpers/util';
import {
  Container, FullHeightBox, AppButton, AppAnchor, Spinning,
} from '@/components/Control';
import { paths } from '@/components/configs';
import {
  StyledCardImage, StyledFeaturedContainer,
} from '../Blog/StyledBlogPage';
import {
  StyledPostContainer,
  StyledTagBadge,
  StyledExtrasContainer,
  StyledTOCList,
} from './StyledBlogPost';


const renderCardImage = (image, small, layout, title) => {
  if (layout === 'landscape' || !layout) {
    return (
      <StyledCardImage
        data-bg={image.imgix_url}
        background={{ image: `url(${optimizeImageSrc(image.imgix_url)})` }}
        borderRadius="0"
        overflow="hidden"
        height={small ? { min: '18rem' } : '100%'}
        width="100%"
        bgSize="100%"
      />
    );
  }

  return (
    <Box overflow="hidden" height={{ max: small ? '20rem' : '24rem' }} pad={{ top: small ? '3.5rem' : '0' }}>
      <Image fit="contain" src={optimizeImageSrc(image.imgix_url)} alt={`${title} cover`} width="100%" height="100%" />
    </Box>
  );
};

const BlogPost = ({
  postUid,
  blogPosts = null,
  isReport = false,
  config: {
    articlesPageBg,
    articlesAltPageBg,
    articlesBg,
    articlesTextColor,
    articlesHighlight,
    articlesLinkHighlight,
    articlesBoxShadow,
    articlesHoverShadow,
  },
}) => {
  const [featuredDate, setFeaturedDate] = React.useState('');
  const [tableOfContents, setTableOfContents] = React.useState(null);
  const small = useClientMediaQuery('(max-width: 768px)');

  React.useEffect(() => {
    if (blogPosts) {
      const activePost = _.find(blogPosts, { slug: postUid });
      const date = new Date(activePost.published_at);
      const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

      setFeaturedDate(date.toLocaleDateString('en-US', dateOptions));

      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(activePost.metadata.articlecontent, 'text/html');
      const allHeaders = htmlDoc.querySelectorAll('h2, h3');
      const headerContents = Array.from(allHeaders).map((node) => node.innerText);

      setTableOfContents(headerContents);
    }
  }, [blogPosts, postUid]);

  if (!blogPosts) {
    return (
      <FullHeightBox pad={{ top: '5.2rem' }} background={articlesPageBg} justify="center" align="center">
        <Spinning color="white" size={small ? 'large' : 'xlarge'} />
      </FullHeightBox>
    );
  }

  const activePostData = _.find(blogPosts, { slug: postUid });
  const activeTags = activePostData?.metadata?.displaytags?.split(',');
  const shareUrl = isReport ? `https://www.redflagai.co/resources/piracy-reports/${postUid}` : `https://www.redflagai.co/resources/blog-posts/${postUid}`;
  const postSources = activePostData?.metadata?.sources?.split(',');

  const isSeries = activePostData?.metadata?.category && activePostData.metadata.category === 'series';
  let nextSeriesPost = null;
  if (isSeries) {
    nextSeriesPost = _.find(blogPosts, (({ metadata }) => metadata.serieskey === activePostData.metadata.serieskey
      && (metadata.seriesorder === activePostData.metadata.seriesorder + 1)));
  }

  const createTOC = (node, children) => {
    if (node.tagName === 'H2') {
      return <h2 id={node.textContent}>{children}</h2>;
    }

    if (node.tagName === 'H3') {
      return <h3 id={node.textContent}>{children}</h3>;
    }
  };

  const createScrollLink = (uid) => {
    if (isReport) {
      return `${paths.piracyReport.replace(':uid', activePostData.slug)}#${uid}`;
    }

    return `${paths.blogPost.replace(':uid', activePostData.slug)}#${uid}`;
  };

  const handleScroll = (uid) => {
    const section = document.getElementById(uid);
    const headerOffset = small ? 100 : 175;
    const sectionPosition = section.getBoundingClientRect().top;
    const offsetPosition = sectionPosition + window.scrollY - headerOffset;

    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
  };

  return (
    <Container fill pad={{ top: '5.2rem' }} background={articlesPageBg}>
      <Box fill="vertical" direction="column">
        <Box
          justify="center"
          align="center"
          pad={{ horizontal: small ? '0' : 'var(--vw7p725)', bottom: '3.5rem', top: small ? '0' : '3.5rem' }}
          background="#F8F8F8"
        >
          <Box
            direction={small ? 'column-reverse' : 'row'}
            height={{ min: '24rem' }}
            width="100%"
            background={articlesAltPageBg}
            round="0.625rem"
          >
            <Box
              direction="column"
              gap={small ? '1rem' : '1.5rem'}
              width={small ? '100%' : '55%'}
              pad={small ? { horizontal: 'var(--vw5p725)', top: '1.5rem' } : { right: '1.5rem', top: '1.5rem' }}
            >
              <Box wrap direction="row">
                {activeTags?.map((tag) => (
                  <StyledTagBadge key={tag}>
                    <Text size="1rem" color={articlesTextColor} weight={500}>{tag}</Text>
                  </StyledTagBadge>
                ))}
              </Box>
              <Box width={small ? '100%' : '85%'}>
                <Heading size={small ? '2.25rem' : '2.5rem'} weight={600} color={articlesTextColor} textAlign="start" margin="none">
                  {activePostData.title}
                </Heading>
              </Box>
              <Box direction="row" gap="0.75rem" align="center">
                <Box
                  background="white"
                  justify="center"
                  align="center"
                  width="3rem"
                  height="3rem"
                  round="50%"
                  border={{ style: 'solid', side: 'all', color: '#D0D0D0' }}
                  pad="0.45rem"
                >
                  <Image
                    fit="contain"
                    src="/images/logo/logo-image-only-color.svg"
                    alt="Redflag AI logo"
                    width="3rem"
                    height="3rem"
                  />
                </Box>
                <Box direction="column" gap="0.35rem">
                  <Text size="1.2rem" weight={500} color={articlesTextColor} textAlign="start">
                    {`${activePostData.metadata.firstname} ${activePostData.metadata.lastname}`}
                  </Text>
                  <Text size="1rem" color="#9C9C9C" textAlign="start">
                    {`${activePostData.metadata.readtime} | Last updated: ${featuredDate}`}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box
              round={{ size: '0.625rem', corner: small ? 'top' : 'right' }}
              width={small ? '100%' : '50%'}
              overflow="hidden"
              pad={(small || activePostData.metadata.imagelayout === 'portrait') ? '0' : { vertical: '1rem' }}
            >
              {renderCardImage(activePostData.metadata.image, small, activePostData.metadata.imagelayout, activePostData.title)}
            </Box>
          </Box>
        </Box>
        <Box direction="row-responsive" pad={{ left: small ? 'var(--vw5p725)' : '1rem', right: small ? 'var(--vw5p725)' : 'var(--vw7p725)', bottom: '3.5rem' }}>
          <StyledExtrasContainer direction="column" gap="1rem" width={small ? '100%' : '23%'} pad={{ top: small ? '2rem' : '1rem', right: small ? '0' : '2rem' }}>
            <AppAnchor target="_blank" href={`https://www.linkedin.com/sharing/share-offsite/?url=${fixedEncodeURIComponent(shareUrl)}`}>
              <Box align="center">
                <Text className="liShare" size={small ? '1.35rem' : '1rem'} color="#65768E">
                  Share on:
                  &nbsp;
                  <Linkedin color="#3C3C3C" size={small ? '1.35rem' : '1.15rem'} />
                </Text>
              </Box>
            </AppAnchor>
            <Box className="toc" background="#F8F8F8" round="6px">
              <Box pad="1.35rem" border={{ style: 'solid', side: 'bottom', color: '#D0D0D0' }}>
                <Heading level={2} margin="none" color={articlesTextColor} size="1.35rem">Table of Contents</Heading>
              </Box>
              <Box pad="1.35rem">
                {tableOfContents ? (
                  <StyledTOCList id="toc">
                    {tableOfContents.filter((item, i) => tableOfContents.indexOf(item) === i).map((dt) => (
                      <li key={uniqid()}>
                        <Link href={createScrollLink(dt)} scroll={false}>
                          <Button plain onClick={() => handleScroll(dt)}>
                            <Text size={small ? '1.1rem' : '0.85rem'} color="#65768E" weight={600}>{dt}</Text>
                          </Button>
                        </Link>
                      </li>
                    ))}
                  </StyledTOCList>
                ) : (
                  <Box flex justify="center" align="center">
                    <Spinning color={articlesHighlight} size="medium" />
                  </Box>
                )}
              </Box>
            </Box>
          </StyledExtrasContainer>
          <Box
            direction="column"
            background={articlesBg}
            gap="3.5rem"
            width={small ? '100%' : '77%'}
            pad={{ top: '1.5rem' }}
          >
            <StyledPostContainer
              isSmall={small}
              fill="horizontal"
              direction="column"
              key={activePostData.slug}
              highlight={articlesLinkHighlight}
              textColor={articlesTextColor}
              pad={{ left: small ? '0' : '3rem' }}
            >
              <Markup transform={createTOC} content={activePostData.metadata.articlecontent} />
              <Box align="start">
                {postSources?.length >= 1 && (
                  <Box direction="column" gap="1rem" pad={{ top: '1.5rem' }}>
                    <Heading level={2} margin="none" color={articlesTextColor}>
                      Sources
                    </Heading>
                    <Box direction="column" gap={small ? '0.3rem' : '0'}>
                      {postSources.map((dt, i) => (
                        <Link key={uniqid()} href={dt} target="_blank" rel="nofollow">
                          <Text size={small ? '0.9rem' : '0.7rem'} color={articlesLinkHighlight}>{`${i + 1}. ${dt}`}</Text>
                        </Link>
                      ))}
                    </Box>
                  </Box>
                )}
                {isSeries && nextSeriesPost && (
                  <Box pad={{ top: '3.5rem' }} width={small ? '100%' : '80%'}>
                    <StyledFeaturedContainer
                      noBorder
                      boxShadow={articlesBoxShadow}
                      hoverShadow={articlesHoverShadow}
                      path={paths.blogPost.replace(':uid', nextSeriesPost.slug)}
                    >
                      <Box
                        direction={small ? 'column-reverse' : 'row'}
                        height={{ min: '21rem' }}
                        width="100%"
                        background={articlesAltPageBg}
                        round="0.625rem"
                        pad={small ? '1.75rem' : '2.5rem'}
                      >
                        <Box direction="column" gap="1rem" width={small ? '100%' : '50%'} justify="center" pad={{ right: small ? '0' : '1.5rem', top: small ? '2rem' : '0' }}>
                          <Text size="1.15rem" color="#174EA6" textAlign="start" style={{ letterSpacing: '0.235px' }} weight={600}>
                            Read the Next Part of the Series
                          </Text>
                          <Text size={small ? '1.65rem' : '1.5rem'} weight={600} color={articlesTextColor} textAlign="start">
                            {nextSeriesPost.title}
                          </Text>
                          <Box width="100%" align="start" pad={{ top: '1rem' }}>
                            <AppButton
                              overrideHover
                              level="dynamicLarge"
                              label="Read Now"
                              alignSelf="start"
                              textColor="white"
                              bgColor={articlesHighlight}
                              path={paths.blogPost.replace(':uid', nextSeriesPost.slug)}
                            />
                          </Box>
                        </Box>
                        <Box round={{ size: '0.625rem', corner: small ? 'top' : 'right' }} width={small ? '100%' : '50%'} overflow="hidden">
                          <StyledCardImage
                            data-bg={nextSeriesPost.metadata.image.imgix_url}
                            background={{ image: `url(${nextSeriesPost.metadata.image.imgix_url})` }}
                            overflow="hidden"
                            height={small ? { min: '16rem' } : '100%'}
                            width="100%"
                            bgSize={small ? '100%' : '145%'}
                          />
                        </Box>
                      </Box>
                    </StyledFeaturedContainer>
                  </Box>
                )}
              </Box>
            </StyledPostContainer>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

BlogPost.propTypes = {
  postUid: PropTypes.string.isRequired,
  config: PropTypes.shape({
    isReport: PropTypes.bool,
    articlesPageBg: PropTypes.string.isRequired,
    articlesAltPageBg: PropTypes.string.isRequired,
    articlesBgImage: PropTypes.string,
    articlesBg: PropTypes.string.isRequired,
    articlesAbstractBg: PropTypes.string.isRequired,
    articlesTextColor: PropTypes.string.isRequired,
    articlesHighlight: PropTypes.string.isRequired,
    articlesAltHighlight: PropTypes.string.isRequired,
    articlesLinkHighlight: PropTypes.string.isRequired,
    articlesBoxShadow: PropTypes.string.isRequired,
    articlesHoverShadow: PropTypes.string.isRequired,
    articlesFollowers: PropTypes.string.isRequired,
    linkedInPosts: PropTypes.arrayOf(PropTypes.shape({
      postUrl: PropTypes.string.isRequired,
      postText: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      hashtags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      linkData: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired).isRequired,
    verticalDisplayMap: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  }).isRequired,
  blogPosts: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      articlecontent: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      readtime: PropTypes.string.isRequired,
      published: PropTypes.string.isRequired,
      category: PropTypes.string,
      serieskey: PropTypes.string,
      seriestitle: PropTypes.string,
      seriesorder: PropTypes.number,
      seriescover: PropTypes.string,
      trending: PropTypes.bool.isRequired,
      thankyoudisplay: PropTypes.bool.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        imgix_url: PropTypes.string.isRequired,
      }).isRequired,
      imagelayout: PropTypes.string.isRequired,
    }).isRequired,
  })),
};

export default BlogPost;
