import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box } from 'grommet';

import { ScrollerHeader } from './ContentSections';
import { StyledTextContent } from './StyledContentSections';


const TeamScroller = ({
  small, title, subTitle, textColor, items,
}) => (
  <Box overflow="hidden">
    <Box direction="column" gap="1.5rem">
      <ScrollerHeader header={title} color={textColor} textAlign={small ? 'start' : 'center'} />
      <Box pad={small ? '0rem' : { horizontal: '25%', bottom: '1rem' }}>
        <StyledTextContent color={textColor} textAlign={small ? 'start' : 'center'}>
          {subTitle}
        </StyledTextContent>
      </Box>
      <Box direction={small ? 'column' : 'row'} gap="1rem" justify={small ? 'start' : 'center'}>
        {items.map(({ groupTitle, members }) => (
          <Box
            key={uniqid()}
            direction="column"
            gap="0.75rem"
            pad={small ? { vertical: '1rem' } : '1rem'}
            width={small ? '100%' : '15rem'}
          >
            <StyledTextContent
              color={textColor}
              size="1.25rem"
              weight={600}
              textAlign="start"
              style={{ letterSpacing: 'normal' }}
            >
              {groupTitle}
            </StyledTextContent>
            <Box direction="column" gap="0.25rem">
              {members.map((member) => (
                <StyledTextContent
                  key={uniqid()}
                  color={textColor}
                  textAlign="start"
                >
                  {member}
                </StyledTextContent>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  </Box>
);

TeamScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    groupTitle: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  })).isRequired,
};

export default TeamScroller;
