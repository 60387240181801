'use client';

import React from 'react';
import PropTypes from 'prop-types';

import { useClientMediaQuery } from '@/lib/clientMediaQuery';

import HeadSection from './HeadSection';
import ContentSections from '../ServiceDynamic/ContentSections';


const HomePage = ({
  industries = null,
  homePageConfig = null,
}) => {
  const small = useClientMediaQuery('(max-width: 768px)');

  if (homePageConfig) {
    const {
      customBgColor,
      fullPageBgImage,
      fullPageBgImageMobile,
      image,
      headerColor,
      preHeaderColor,
      preHeader,
      header,
      subHeader,
      headerApproach,
      scrollers,
    } = homePageConfig;

    return (
      <>
        <HeadSection
          small={small}
          customBgColor={customBgColor}
          fullPageBgImage={fullPageBgImage}
          fullPageBgImageMobile={fullPageBgImageMobile}
          image={image}
          headerColor={headerColor}
          preHeaderColor={preHeaderColor}
          preHeader={preHeader}
          header={header}
          subHeader={subHeader}
          headerApproach={headerApproach}
        />
        <ContentSections
          small={small}
          scrollers={scrollers}
          industries={industries}
        />
      </>
    );
  }

  return null;
};

HomePage.propTypes = {
  industries: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  })),
  homePageConfig: PropTypes.shape({
    customBgColor: PropTypes.string.isRequired,
    fullPageBgImage: PropTypes.string.isRequired,
    fullPageBgImageMobile: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    headerColor: PropTypes.string.isRequired,
    preHeaderColor: PropTypes.string,
    preHeader: PropTypes.string,
    header: PropTypes.shape({
      bold: PropTypes.string,
      rest: PropTypes.string.isRequired,
    }).isRequired,
    subHeader: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    headerApproach: PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      hoverColor: PropTypes.string.isRequired,
    }),
    scrollers: PropTypes.objectOf(PropTypes.any).isRequired,
  }),
};

export default HomePage;
