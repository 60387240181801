import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';

import { Container } from '@/components/Control';
import HeadSection from '../HeadSection';


const ContentSectionWrapper = ({ background, children }) => (
  <Box overflow="hidden" background={background}>
    <Container
      pad={{ horizontal: 'var(--vw5p725)', vertical: '4.375rem' }}
      justify="center"
    >
      {children}
    </Container>
  </Box>
);

ContentSectionWrapper.propTypes = {
  background: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};


const SuperbowlStudiesPage = ({
  small,
  carouselImages,
  fullScreenBg = null,
  preHeader = null,
  header,
  subHeader = null,
  headersColor,
  headerApproach = null,
  highlightColor,
  customBgColor,
}) => (
  <Box>
    <HeadSection
      smallerHeader
      small={small}
      carouselImages={carouselImages}
      fullScreenBg={fullScreenBg}
      preHeader={preHeader}
      header={header}
      subHeader={subHeader}
      headersColor={headersColor}
      headerApproach={headerApproach}
      highlightColor={highlightColor}
      customBgColor={customBgColor}
    />
  </Box>
);

SuperbowlStudiesPage.propTypes = {
  small: PropTypes.bool.isRequired,
  carouselImages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  fullScreenBg: PropTypes.string,
  preHeader: PropTypes.string,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
  headersColor: PropTypes.string.isRequired,
  headerApproach: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }),
  highlightColor: PropTypes.string.isRequired,
  customBgColor: PropTypes.string.isRequired,
};

export default SuperbowlStudiesPage;
