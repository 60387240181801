import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box } from 'grommet';

import DemoRowScroller from './DemoRowScroller';


const MultiDemoRowScroller = ({
  small, textColor, headerColor, borderRadius, sections,
}) => (
  <Box overflow="hidden" direction="column" gap="5rem">
    {sections.map((props) => (
      <DemoRowScroller
        key={uniqid()}
        small={small}
        textColor={textColor}
        headerColor={headerColor}
        headerAlignOverride="start"
        demoHeightOverride={small ? '16rem' : '22rem'}
        borderRadius={borderRadius}
        {...props}
      />
    ))}
  </Box>
);

MultiDemoRowScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  headerColor: PropTypes.string,
  borderRadius: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string.isRequired,
    subHeader: PropTypes.string.isRequired,
    reverse: PropTypes.bool,
    loaderImage: PropTypes.string.isRequired,
    layout: PropTypes.string,
    animation: PropTypes.shape({
      mp4: PropTypes.string.isRequired,
      webm: PropTypes.string.isRequired,
    }),
    approach: PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      hoverColor: PropTypes.string.isRequired,
    }),
  })).isRequired,
};

export default MultiDemoRowScroller;