'use client';

import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import _ from 'lodash';

import { Box, Text } from 'grommet';

import { useClientMediaQuery } from '@/lib/clientMediaQuery';
import { FullHeightBox, Spinning } from '@/components/Control';

import { ScrollerHeader } from '../ServiceDynamic/ContentSections/ContentSections';
import HeadSection from '../ServiceDynamic/HeadSection';
import { ClientStudyCard, InteractiveStudyCard } from '../CaseStudy/CaseStudyPage';


const CaseStudiesPage = ({ config = null }) => {
  const small = useClientMediaQuery('(max-width: 768px)');

  if (!config) {
    return (
      <FullHeightBox pad={{ top: '5.2rem' }} background="white" justify="center" align="center">
        <Spinning color="white" size={small ? 'large' : 'xlarge'} />
      </FullHeightBox>
    );
  }

  const {
    highlightColor,
    embedsSectionHeader,
    embedsSectionSubheader,
    embedsBg,
    embedsTitleColor,
    embedStudyBanner,
    clientStudiesBg,
    clientStudiesHeader,
    primaryTextColor,
    caseStudies,
    clientStudies,
    ...rest
  } = config;

  return (
    <Box overflow="hidden">
      <HeadSection
        small={small}
        highlightColor={highlightColor}
        {...rest}
      />
      <Box background={embedsBg} pad={{ vertical: '3rem', horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)' }} gap="1.5rem">
        <Box direction="column" gap="1rem">
          <ScrollerHeader header={embedsSectionHeader} color={embedsTitleColor} textAlign="center" />
          {embedsSectionSubheader && (
            <Text color={embedsTitleColor} size="1rem" weight={400} textAlign="center">{embedsSectionSubheader}</Text>
          )}
        </Box>
        <Box flex wrap={small} direction="row" justify={small ? 'start' : 'center'}>
          {caseStudies.map(({ uid: studyUid, coverImage }) => (
            <InteractiveStudyCard
              key={uniqid()}
              small={small}
              studyUid={studyUid}
              coverImage={coverImage}
              textColor={primaryTextColor}
            />
          ))}
        </Box>
      </Box>
      <Box background={clientStudiesBg} pad={{ vertical: '3rem', horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)' }} gap="1.5rem">
        <Box direction="column" gap="1rem">
          <ScrollerHeader header={clientStudiesHeader} color={primaryTextColor} textAlign="center" />
        </Box>
        <Box flex wrap={!small} direction={small ? 'column' : 'row'} justify="center" align="center" gap={small ? '1rem' : '0.5rem'}>
          {clientStudies.map(({
            uid: clientUid, coverImage, title,
          }) => (
            <ClientStudyCard
              key={uniqid()}
              small={small}
              clientUid={clientUid}
              title={title}
              coverImage={coverImage}
              textColor={primaryTextColor}
              highlightColor={highlightColor}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

CaseStudiesPage.propTypes = {
  config: PropTypes.shape({
    fullHeightHeadSection: PropTypes.bool,
    preHeader: PropTypes.string,
    preHeaderColor: PropTypes.string,
    header: PropTypes.string.isRequired,
    desktopHeader: PropTypes.string,
    desktopHeaderLogo: PropTypes.string,
    subHeader: PropTypes.arrayOf(PropTypes.string.isRequired),
    headerColor: PropTypes.string.isRequired,
    headersBg: PropTypes.string,
    image: PropTypes.string,
    fullPageBgImage: PropTypes.string,
    fullPageBgImageMobile: PropTypes.string,
    customBgColor: PropTypes.string.isRequired,
    highlightColor: PropTypes.string.isRequired,
    headerApproach: PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      hoverColor: PropTypes.string.isRequired,
    }),
    embedsBg: PropTypes.string.isRequired,
    embedsTitleColor: PropTypes.string.isRequired,
    embedsSectionHeader: PropTypes.string.isRequired,
    embedsSectionSubheader: PropTypes.string.isRequired,
    embedStudyBanner: PropTypes.string.isRequired,
    clientStudiesBg: PropTypes.string.isRequired,
    clientStudiesHeader: PropTypes.string.isRequired,
    primaryTextColor: PropTypes.string.isRequired,
    caseStudies: PropTypes.arrayOf(PropTypes.shape({
      uid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      embedUrl: PropTypes.string.isRequired,
      description: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      coverImage: PropTypes.string.isRequired,
      carouselImages: PropTypes.arrayOf(PropTypes.string.isRequired),
      fullScreenBg: PropTypes.string,
      disclaimer: PropTypes.string,
      preHeader: PropTypes.string,
      header: PropTypes.string,
      subHeader: PropTypes.string,
      headersColor: PropTypes.string,
      highlightColor: PropTypes.string,
      customBgColor: PropTypes.string,
    }).isRequired).isRequired,
    clientStudies: PropTypes.arrayOf(PropTypes.shape({
      uid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      coverImage: PropTypes.string.isRequired,
      pdf: PropTypes.string.isRequired,
    }).isRequired).isRequired,
  }),
};

export default CaseStudiesPage;
