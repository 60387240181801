import styled from 'styled-components';

import { Box, Heading } from 'grommet';

const StyledIllustrationWrapper = styled(Box)`
  background-repeat: no-repeat;
  background-size: 40%;
  min-height: min(35vw, 1440px);
  max-height: min(100vh, 1600px);
  background-position: right 12% center;

  @media (max-width: 1070px) {
    min-height: min(55vw, 1440px);
  }

  @media (max-width: 768px) {
    background-position: 50% 10%;
    background-size: 80%;
    height: auto;
    min-height: 75vh;
    max-height: none;
  }

  @media (max-width: 450px) {
    background-size: 90%;
  }
`;

const StyledExtraLargeHeader = styled(Heading)`
  letter-spacing: normal;
  font-size: 3rem;
  font-weight: 600;
  white-space: pre-line;
  line-height: 1.2;
`;

const StyledHeader = styled(Heading)`
  letter-spacing: normal;
  font-size: 2.85rem;
  font-weight: 600;
  white-space: pre-line;
  line-height: 1.2;

  @media (max-width: 1070px) {
    font-size: 2.15rem;
  }

  @media (max-width: 768px) {
    font-size: 2.65rem;
  }

  @media (max-width: 375px) {
    font-size: 2.35rem;
  }
`;

const StyledSubHeader = styled(Heading)`
  letter-spacing: ${({ spacing }) => spacing || 'normal'};
  font-weight: ${({ weight }) => weight || 'normal'};
  font-size: ${({ size }) => (size || '1rem')};
  line-height: ${({ lineHeight }) => (lineHeight || '1.35')};

  @media (max-width: 768px) {
    font-size: ${({ size }) => (size || '1.125rem')};
  }
`;

export {
  StyledIllustrationWrapper,
  StyledHeader,
  StyledExtraLargeHeader,
  StyledSubHeader,
};
