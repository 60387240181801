import React from 'react';
import styled from 'styled-components';

import { Text } from 'grommet';
import { AppAnchor } from '@/components/Control';

const StyledPostAnchor = styled((props) => <AppAnchor {...props} />)`
  text-decoration: none;
  background: ${({ bgColor }) => bgColor};
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 0.5rem;
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
`;

const StyledHeading = styled(Text)`
  line-height: 1.2;
`;

export { StyledPostAnchor, StyledHeading };
