'use client';

import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import {
  Box, Button, Text, Image,
} from 'grommet';

import { optimizeImageSrc } from '@/helpers/util';
import { ScrollerHeader } from './ContentSections';
import { StyledCarousel, StyledTextContent } from './StyledContentSections';


const MobileCarousel = ({
  small, title, titleColor, preTitle, preTitleColor, textColor, dividerIcon, items,
}) => {
  const [activeSlide, setActiveSlide] = React.useState(0);

  const renderSection = (header, text, image, index) => (
    <React.Fragment key={uniqid()}>
      <Box fill direction="column" justify="between">
        <Box align="center" justify="start" pad={{ horizontal: '1.5rem' }} gap="1rem" height={small ? '8rem' : '10rem'}>
          <Text weight={600} color={textColor} textAlign="center" size={small ? '1.35rem' : '1.25rem'}>{`${index}. ${header}`}</Text>
          <StyledTextContent color={textColor} textAlign="center">{text}</StyledTextContent>
        </Box>
        <Box height="26rem">
          <Image src={optimizeImageSrc(image)} fit="contain" alt={`${header} image`} />
        </Box>
      </Box>
      {!small && index < items.length && (
        <Box align="center" justify="center" height="100%" width="6.5rem" style={{ alignSelf: 'center' }} pad={{ top: '15%' }}>
          <Image src={optimizeImageSrc(dividerIcon)} fit="contain" alt="Divider right chevron icon" />
        </Box>
      )}
    </React.Fragment>
  );

  return (
    <Box overflow="hidden" pad={{ bottom: small ? '3.75rem' : '0' }}>
      <Box direction="column" gap="2rem">
        <Box direction="column" gap="1rem">
          {preTitle && (
            <Text uppercase size={small ? '1rem' : '0.9rem'} color={preTitleColor} weight={600} style={{ letterSpacing: '2.8px' }} textAlign="center">
              {preTitle}
            </Text>
          )}
          <ScrollerHeader header={title} color={titleColor} />
        </Box>
        <Box flex justify="center" align="start" direction={small ? 'column' : 'row'}>
          {!small && items.map(({ header, text, image }, i) => (
            renderSection(header, text, image, i + 1)
          ))}
          {small && (
            <Box>
              <StyledCarousel
                emulateTouch
                isSmall={small}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={activeSlide}
                onChange={(index) => setActiveSlide(index)}
                width="100%"
                alignSelf="center"
              >
                {items.map(({ header, text, image }, i) => (
                  renderSection(header, text, image, i + 1)
                ))}
              </StyledCarousel>
              <Box direction="row" gap="0.75rem" justify="center">
                {items.map((_, i) => (
                  <Button key={uniqid()} onClick={() => setActiveSlide(i)}>
                    <Text size="3.5rem" color={i === activeSlide ? preTitleColor : '#D0D0D0'}>
                      •
                    </Text>
                  </Button>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

MobileCarousel.propTypes = {
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  preTitle: PropTypes.string.isRequired,
  preTitleColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  dividerIcon: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  })).isRequired,
};

export default MobileCarousel;