import React from 'react';
import styled from 'styled-components';

import {
  Box, Heading,
} from 'grommet';

import { AppAnchor } from '@/components/Control';

const StyledPostContainer = styled(Box)`
  a {
    text-decoration: none;
    color: ${({ highlight }) => highlight};
  }

  h1 {
    font-size: 2.25rem;
    color: ${({ textColor }) => textColor};
  }

  h2 {
    font-size: 2rem;
    color: ${({ textColor }) => textColor};
  }

  h3, h4 {
    font-size: 1.5rem;
    color: ${({ textColor }) => textColor};
  }

  p, li {
    font-size: 1.125rem;
    line-height: 1.625;
    color: ${({ textColor }) => textColor};
  }

  img {
    max-width: 100% !important;
    display: block;
    height: auto !important;
  }

  pre {
    white-space: pre-wrap;
  }

  table {
    border-collapse: collapse;

    td {
      padding: 5px;
      border: solid 1px #D0D0D0;
    }
  }

  @media (max-width: 768px) {
    h1 { font-size: 2.4rem; }
    h2 { font-size: 2.25rem; }
    h3, h4 { font-size: 2rem; }
    p, li { font-size: 1.35rem; }

    img {
      width: 100% !important;
    }
  }
`;

const StyledPostTitle = styled(Heading)`
  line-height: 1.2;
  font-size: 2.15rem;
  font-weight: 600;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const StyledPostAnchor = styled((props) => <AppAnchor {...props} />)`
  text-decoration: none;
  background-color: ${({ background }) => background};
  border-radius: 2rem;
  margin: 1.5rem;
  height: 20rem;
  width: 15rem;
  box-shadow: ${({ boxShadow }) => boxShadow};
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    text-decoration: none;
    box-shadow: ${({ hoverShadow }) => hoverShadow};
  }
`;

const StyledTagBadge = styled(Box)`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.75rem;
  background-color: #E9EBED;
  border-radius: 10px;
  padding: 0.4rem 0.5rem;
  font-weight: 600;
  font-size: 1rem;

  @media (max-width: 768px) {
    padding: 0.5rem 0.6rem;
  }
`;

const StyledExtrasContainer = styled(Box)`
  position: -webkit-sticky;
  position: sticky;
  align-self: flex-start;
  top: 6.375rem;

  span.liShare {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  h2 {
    font-weight: 600;
  }

  @media (max-width: 768px) {
    top: 0;
    position: relative;
  }
`;

const StyledTOCList = styled.ul`
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  list-style-type: none;

  li:not(last-child) {
    padding-bottom: 0.5rem;

    @media (max-width: 768px) {
      padding-bottom: 1.35rem;
    }
  }
`;

export {
  StyledPostContainer,
  StyledPostTitle,
  StyledPostAnchor,
  StyledTagBadge,
  StyledExtrasContainer,
  StyledTOCList,
};
