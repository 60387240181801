import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import {
  Box, Text, Image,
} from 'grommet';

import { paths } from '@/components/configs';
import { optimizeImageSrc } from '@/helpers/util';
import {
  StyledTextContent,
  StyledFlexGrowContainer,
  StyledScrollerSubHeader,
  StyledServicesAnchor,
} from './StyledContentSections';


const ServicesScroller = ({
  small,
  firstSectionHeader,
  firstSectionParagraph,
  secondSectionHeader,
  secondSectionItems,
  primaryTextColor,
  headerColor,
}) => (
  <Box overflow="hidden">
    <Box direction={small ? 'column' : 'row'} gap={small ? '3rem' : '0'}>
      <StyledFlexGrowContainer flexGrow={1} gap="1.5rem">
        <Text uppercase weight={600} style={{ letterSpacing: '2.8px' }} color={headerColor} size="1rem">
          {firstSectionHeader}
        </Text>
        <Box width="100%" direction="column" gap="1rem" pad={{ right: small ? '0' : '20%' }}>
          {firstSectionParagraph.map((p, i) => (
            <StyledTextContent
              key={uniqid()}
              color={primaryTextColor}
              textAlign="start"
              weight={(i === firstSectionParagraph.length - 1) ? 600 : 'normal'}
              size="1rem"
            >
              {p}
            </StyledTextContent>
          ))}
        </Box>
      </StyledFlexGrowContainer>
      <StyledFlexGrowContainer flexGrow={1} gap="1.5rem">
        <Text uppercase weight={600} style={{ letterSpacing: '2.8px' }} color={headerColor} size="1rem">
          {secondSectionHeader}
        </Text>
        <Box wrap={!small} direction={small ? 'column' : 'row'}>
          {secondSectionItems.map(({
            icon, header, text, industryKey,
          }) => (
            <StyledServicesAnchor key={industryKey} isSmall={small} path={paths.industries.replace(':uid', industryKey)}>
              <Box
                direction="column"
                align="start"
                justify="center"
                pad={{ bottom: '2rem', right: small ? '0' : '1.5rem' }}
                gap="0.65rem"
              >
                <Box
                  direction="row"
                  justify="start"
                  align="center"
                  gap="1rem"
                >
                  <Box width="3.25rem" height="3.25rem">
                    <Image
                      src={small ? `${optimizeImageSrc(icon)}&w=350` : optimizeImageSrc(icon)}
                      alt={`${header} icon`}
                      fit="contain"
                      width="3.25rem"
                      height="3.25rem"
                    />
                  </Box>
                  <Box justify="center">
                    <StyledScrollerSubHeader color={primaryTextColor} size={small ? '1.4rem' : '1.2rem'} weight={600} margin="none">
                      {header}
                    </StyledScrollerSubHeader>
                  </Box>
                </Box>
                <StyledTextContent isSmall={small} color={primaryTextColor}>
                  {text}
                </StyledTextContent>
              </Box>
            </StyledServicesAnchor>
          ))}
        </Box>
      </StyledFlexGrowContainer>
    </Box>
  </Box>
);

ServicesScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  firstSectionHeader: PropTypes.string.isRequired,
  secondSectionHeader: PropTypes.string.isRequired,
  primaryTextColor: PropTypes.string.isRequired,
  headerColor: PropTypes.string.isRequired,
  firstSectionParagraph: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  secondSectionItems: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    industryKey: PropTypes.string.isRequired,
  })).isRequired,
};

export default ServicesScroller;
