'use client';

import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box } from 'grommet';

import { optimizeImageSrc } from '@/helpers/util';
import { ApproachButton } from '../ServiceDynamic/ContentSections/ContentSections';
import { StyledPreHeader } from '../ServiceDynamic/HeadSection/StyledHeadSection';
import {
  StyledIllustrationWrapper, StyledHeader, StyledExtraLargeHeader, StyledSubHeader,
} from './StyledHomePage';


const HeadSection = ({
  small,
  customBgColor,
  fullPageBgImage,
  fullPageBgImageMobile,
  headerColor,
  preHeaderColor = null,
  preHeader = null,
  header,
  subHeader,
  image,
  headerApproach = null,
}) => (
  <Box
    overflow="hidden"
    background={{
      image: small ? `url(${optimizeImageSrc(fullPageBgImageMobile)})` : `url(${optimizeImageSrc(fullPageBgImage)})`,
      color: customBgColor,
    }}
    pad={{ top: '5.375rem' }}
  >
    <StyledIllustrationWrapper
      fill
      data-bg={optimizeImageSrc(image)}
      background={{ image: small ? `url(${optimizeImageSrc(image)}&w=1024)` : `url(${optimizeImageSrc(image)})` }}
      pad={{ horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)' }}
      justify={small ? null : 'center'}
    >
      <Box gap="0.75rem" pad={small ? { bottom: 'calc(40px + var(--vw4))' } : null}>
        <Box direction="column" margin={small ? { top: 'calc(var(--vw31) + var(--vw31) + 7vh)' } : { bottom: '-1.15rem' }}>
          {preHeader && (
            <Box pad={{ bottom: '0.75rem' }}>
              <StyledPreHeader color={preHeaderColor || headerColor} textAlign="start">
                {preHeader}
              </StyledPreHeader>
            </Box>
          )}
          {header.bold && (
            <StyledExtraLargeHeader level={1} margin="none" color={headerColor} textAlign="start">
              {header.bold}
              &nbsp;
            </StyledExtraLargeHeader>
          )}
          <Box width={small ? '100%' : '45%'}>
            <StyledHeader level={1} margin="none" color={headerColor} textAlign="start">
              {header.rest}
            </StyledHeader>
          </Box>
        </Box>
        <Box direction="column" width={small ? '100%' : '45%'} pad={{ top: small ? '0.75rem' : '1.5rem' }}>
          {subHeader.map((dt) => (
            <StyledSubHeader key={uniqid()} level={2} color={headerColor} margin="none" textAlign="start">
              {dt}
            </StyledSubHeader>
          ))}
        </Box>
        {headerApproach && (
          <Box pad={{ top: '1rem' }} align="start">
            <ApproachButton small={small} approach={headerApproach} trackLocation={`${small ? 'Mobile' : 'Desktop'} Homepage Hero - Book a Demo click`} />
          </Box>
        )}
      </Box>
    </StyledIllustrationWrapper>
  </Box>
);

HeadSection.propTypes = {
  small: PropTypes.bool.isRequired,
  customBgColor: PropTypes.string.isRequired,
  fullPageBgImage: PropTypes.string.isRequired,
  fullPageBgImageMobile: PropTypes.string.isRequired,
  headerColor: PropTypes.string.isRequired,
  preHeaderColor: PropTypes.string,
  preHeader: PropTypes.string,
  header: PropTypes.shape({
    bold: PropTypes.string,
    rest: PropTypes.string.isRequired,
  }).isRequired,
  subHeader: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  image: PropTypes.string.isRequired,
  headerApproach: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }),
};

export default HeadSection;
