'use client';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { createBucketClient } from '@cosmicjs/sdk';

import { Box, Text } from 'grommet';
import { FormNext } from 'grommet-icons';

import {
  Container, FullHeightBox, PlaceholderLoader,
} from '@/components/Control';
import { paths } from '@/components/configs';
import { StyledPostAnchor, StyledHeading } from './StyledThankYouPage';

const cosmic = createBucketClient({
  bucketSlug: 'redflag-ai-production',
  readKey: process.env.COSMIC_API_READ_KEY,
});

const fetchPosts = async () => {
  const data = await cosmic.objects.find({ type: 'blog-posts' }).props('slug,title,content,metadata');

  return data;
};

const ThankYouPage = ({
  small,
  config: {
    pageBg, textColor, altTextColor,
  },
}) => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    fetchPosts().then((result) => setData(result));
  });

  const posts = data && data.objects;
  const displayPosts = posts && _.filter(posts, { metadata: { thankyoudisplay: true } });

  return (
    <FullHeightBox fill justify="center" pad={{ top: '5.2rem' }} background={pageBg}>
      <Container margin={{ vertical: '5.2rem' }}>
        <Box direction="row-responsive" gap="5rem" pad={{ horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)' }}>
          <Box
            direction="column"
            gap="1.5rem"
            width={small ? '100%' : { max: 'var(--vw30)' }}
            margin={small ? { bottom: '2.5rem' } : null}
            justify="center"
          >
            <StyledHeading
              textAlign="start"
              color={textColor}
              size={small ? '3.5rem' : '4.5rem'}
              weight={700}
            >
              Thank you
            </StyledHeading>
            <Text textAlign="start" color={textColor} size={small ? '1rem' : '1.25rem'}>
              We look forward to speaking with you soon! Take a look at some of our articles
              to learn more about how Redflag AI can help your business.
            </Text>
          </Box>
          <Box direction="column" pad={{ top: '1rem' }}>
            {displayPosts && displayPosts.length
              ? (
                <Box gap="2rem">
                  {displayPosts.slice(0, 3).map((post) => (
                    <StyledPostAnchor key={post.slug} path={paths.blogPost.replace(':uid', post.slug)} bgColor={pageBg}>
                      <Box direction="column" gap="1.25rem" pad="2rem">
                        <StyledHeading size="1.5rem" weight={600} color={textColor}>{post.title}</StyledHeading>
                        <Box align="center" justify="end" direction="row">
                          <Text size="1.25rem" color={altTextColor}>Read the article</Text>
                          <Box direction="row" pad={{ top: '0.25rem' }}>
                            <FormNext size="1rem" color={altTextColor} />
                          </Box>
                        </Box>
                      </Box>
                    </StyledPostAnchor>
                  ))}
                </Box>
              ) : <PlaceholderLoader.PDF />}
          </Box>
        </Box>
      </Container>
    </FullHeightBox>
  );
};

ThankYouPage.propTypes = {
  small: PropTypes.bool.isRequired,
  config: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    altTextColor: PropTypes.string.isRequired,
  }).isRequired,
};

export default ThankYouPage;
