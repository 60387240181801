import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';

import { optimizeImageSrc } from '@/helpers/util';
import { ApproachButton, ScrollerHeader } from './ContentSections';
import {
  StyledAspectScrollerDemo,
  StyledDemoVideo,
  StyledFlexGrowContainer,
  StyledScrollerSubHeader,
  StyledTextContent,
  StyledCardImage,
} from './StyledContentSections';


const DemoRowScroller = ({
  small,
  dualImage = false,
  header = null,
  headerColor = null,
  headerAlignOverride = null,
  subHeader = null,
  textColor = null,
  reverse = false,
  borderRadius = null,
  demoHeightOverride = null,
  loaderImage,
  loaderImageFlex = null,
  secondImage = null,
  secondImageFlex = null,
  secondImageMobile = null,
  layout = null,
  animation = null,
  approach = null,
}) => {
  const isVertical = layout && layout === 'vertical';
  let demoHeight = small ? '22rem' : '24rem';
  let renderImage = loaderImage;
  let layoutDirection = small ? 'column' : 'row';
  let alignHeader = (small || isVertical) ? 'center' : 'start';

  if (reverse && !small) layoutDirection = 'row-reverse';
  if (isVertical) layoutDirection = 'column';

  if (dualImage) {
    renderImage = small ? secondImageMobile : secondImage;
    demoHeight = small ? '30rem' : '36rem';
  }

  if (!dualImage && !animation) {
    demoHeight = small ? '16rem' : '32rem';
  }

  if (headerAlignOverride) alignHeader = headerAlignOverride;
  if (demoHeightOverride) demoHeight = demoHeightOverride;

  const renderContent = (imageContent, video) => {
    if (video) {
      const videoSrc = Object.keys(video).reduce((result, key) => {
        result[key] = video[key];
        return result;
      }, {});

      return (
        <StyledAspectScrollerDemo overflow="hidden" width="100%" height={demoHeight}>
          <StyledDemoVideo lazySrc={optimizeImageSrc(loaderImage)} src={videoSrc} fit="contain" height="100%" />
        </StyledAspectScrollerDemo>
      );
    }

    return (
      <StyledCardImage
        data-bg={optimizeImageSrc(imageContent)}
        background={{ image: `url(${optimizeImageSrc(imageContent)})` }}
        overflow="hidden"
        height={demoHeight}
        width="100%"
        round={borderRadius}
      />
    );
  };

  return (
    <Box overflow="hidden">
      <Box flex direction={layoutDirection} align="center" gap={small ? '1.5rem' : '3rem'}>
        {!dualImage && header && (
          <StyledFlexGrowContainer flexGrow={secondImageFlex || 1} gap="1.5rem">
            <ScrollerHeader header={header} color={headerColor} textAlign={alignHeader} />
            {subHeader && (
              <Box width={small ? null : { max: '75%' }}>
                <StyledTextContent color={textColor} textAlign="start">
                  {subHeader}
                </StyledTextContent>
              </Box>
            )}
            {approach && !small && (
              <Box margin={{ top: subHeader ? '0.5rem' : '1.5rem' }}>
                <ApproachButton small={false} approach={approach} />
              </Box>
            )}
          </StyledFlexGrowContainer>
        )}
        {dualImage && (
          <StyledFlexGrowContainer flexGrow={loaderImageFlex || 1} width={small ? { min: '100%' } : '100%'} height={demoHeight}>
            {renderContent(loaderImage, animation)}
          </StyledFlexGrowContainer>
        )}
        <StyledFlexGrowContainer flexGrow={secondImageFlex || 1} width={small ? { min: '100%' } : '100%'} height={demoHeight}>
          {renderContent(renderImage, animation)}
        </StyledFlexGrowContainer>
        {small && approach && (
          <Box align="center">
            <ApproachButton small={small} approach={approach} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

DemoRowScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  dualImage: PropTypes.bool,
  header: PropTypes.string,
  headerColor: PropTypes.string,
  headerAlignOverride: PropTypes.string,
  subHeader: PropTypes.string,
  textColor: PropTypes.string,
  reverse: PropTypes.bool,
  borderRadius: PropTypes.string,
  loaderImage: PropTypes.string.isRequired,
  loaderImageFlex: PropTypes.number,
  secondImage: PropTypes.string,
  secondImageMobile: PropTypes.string,
  secondImageFlex: PropTypes.number,
  demoHeightOverride: PropTypes.string,
  layout: PropTypes.string,
  animation: PropTypes.shape({
    mp4: PropTypes.string.isRequired,
    webm: PropTypes.string.isRequired,
  }),
  approach: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }),
};

export default DemoRowScroller;
