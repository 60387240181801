import React from 'react';
import PropTypes from 'prop-types';
import Case from 'case';
import uniqid from 'uniqid';
import dynamic from 'next/dynamic';
import { track } from '@vercel/analytics';

import { Box, Text } from 'grommet';

import {
  AppAnchor, AppButton, Container,
} from '@/components/Control';
import { paths } from '@/components/configs';
import { optimizeImageSrc } from '@/helpers/util';
import { StyledScrollerHeader, StyledTextContent } from './StyledContentSections';

import CardsScroller from './CardsScroller';
import ResourcesScroller from './ResourcesScroller';
import IndustriesScroller from './IndustriesScroller';
import TechStackScroller from './TechStackScroller';
import IllustrationCardsScroller from './IllustrationCardsScroller';
import ListCardsScroller from './ListCardsScroller';
import TeamScroller from './TeamScroller';
import IllustrationScroller from './IllustrationScroller';
import TabScroller from './TabScroller';
import ServicesScroller from './ServicesScroller';
import SocialMediaScroller from './SocialMediaScroller';
import InfoRowScroller from './InfoRowScroller';
import IntroTextScroller from './IntroTextScroller';
import SliderScroller from './SliderScroller';
import DemoRowScroller from './DemoRowScroller';
import MultiDemoRowScroller from './MultiDemoRowScroller';
import VideoScroller from './VideoScroller';
import IconsScroller from './IconsScroller';
import CareersScroller from './CareersScroller';
import MarqueeTextScroller from './MarqueeTextScroller';
import CTAScroller from './CTAScroller';
import FAQScroller from './FAQScroller';
import IconCardsScroller from './IconCardsScroller';
import MobileCarousel from './MobileCarousel';
const TrendingScroller = dynamic(() => import('./Trending'), { ssr: false });


export const renderListText = (small, listText, textColor, highlight = null) => (
  <Box
    flex
    direction="column"
    fill="horizontal"
    gap="0.75rem"
  >
    {listText.map((li) => (
      <Box key={uniqid()} direction="row" gap="0.25rem" align="start">
        <StyledTextContent isSmall={small} color={highlight || textColor} size="1.2rem">•</StyledTextContent>
        <StyledTextContent isSmall={small} color={textColor}>
          {li}
        </StyledTextContent>
      </Box>
    ))}
  </Box>
);

export const ScrollerHeader = ({
  color, header, textAlign = null, ...rest
}) => (
  <StyledScrollerHeader
    level={2}
    color={color}
    margin="none"
    textAlign={textAlign || 'center'}
    {...rest}
  >
    {header}
  </StyledScrollerHeader>
);

ScrollerHeader.propTypes = {
  color: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
};

export const ApproachButton = ({
  small,
  approach: {
    uid, text, email, path, color, hoverColor, dynamicTrack,
  },
  trackLocation,
  ...rest
}) => {
  const anchorPath = (uid === 'register') ? paths[`pricing${Case.capital(path || 'insight')}`] : path;
  const href = (uid === 'email' || email) ? `mailto:${email}` : null;
  const extraWide = text.length >= 13;
  const withTracking = trackLocation || dynamicTrack;
  let useTrack = null;

  if (withTracking) useTrack = trackLocation ?? `${small ? 'Mobile' : 'Desktop'} ${dynamicTrack}`;

  return (
    <Box>
      <AppAnchor href={href} path={(uid === 'email' || email) ? null : anchorPath} {...rest}>
        <AppButton
          overrideHover
          onClick={withTracking && useTrack && (() => track(useTrack))}
          extraWide={extraWide}
          level="dynamicLarge"
          bgColor={color}
          color={hoverColor}
          label={text}
          fontWeight={600}
        />
      </AppAnchor>
    </Box>
  );
};

ApproachButton.propTypes = {
  small: PropTypes.bool.isRequired,
  approach: PropTypes.shape({
    uid: PropTypes.string,
    email: PropTypes.string,
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    dynamicTrack: PropTypes.string,
  }).isRequired,
  alignSelf: PropTypes.string,
  trackLocation: PropTypes.string,
};

const ProblemStatementScroller = ({
  small,
  header,
  paragraph,
  textColor,
}) => (
  <Box overflow="hidden">
    <Box
      direction="column"
      gap="1rem"
      width={small ? '100%' : '60%'}
      justify={small ? 'end' : 'center'}
      height={small ? '35rem' : '20rem'}
    >
      <Box justify="start">
        <Text uppercase weight={600} style={{ letterSpacing: '2.8px' }} color={textColor} size="1rem">
          {header}
        </Text>
      </Box>
      <Box width="100%" direction="column" gap="1rem">
        {paragraph.map((p) => (
          <StyledTextContent
            key={uniqid()}
            color={textColor}
            textAlign="start"
            weight={600}
            size="1.75rem"
          >
            {p}
          </StyledTextContent>
        ))}
      </Box>
    </Box>
  </Box>
);

ProblemStatementScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  paragraph: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  textColor: PropTypes.string.isRequired,
};

const ContentSectionWrapper = ({
  small,
  noPadHorizontal = false,
  lessPadHorizontal = false,
  zeroPad = false,
  noPadTop = false,
  noPadBottom = false,
  lessPadTop = false,
  lessPadBottom = false,
  background,
  mobileBackground = null,
  children = null,
}) => {
  let horizontalPad = small ? 'var(--vw5p725)' : 'var(--vw7p725)';
  let topPad = lessPadTop ? '1.5rem' : '3.75rem';
  let bottomPad = lessPadBottom ? '1.5rem' : '3.75rem';
  let useBg = background;

  if (noPadHorizontal || zeroPad) {
    horizontalPad = null;
  }

  if (lessPadHorizontal) horizontalPad = small ? 'var(--vw5p725)' : 'var(--vw3p25)';

  if (zeroPad) {
    topPad = null;
    bottomPad = null;
  }

  if (noPadTop) {
    topPad = null;
  }

  if (noPadBottom) {
    bottomPad = null;
  }

  if (small && mobileBackground) {
    useBg = mobileBackground;
  }

  if (!children) return null;

  if (useBg.startsWith('https')) {
    return (
      <Box
        overflow="hidden"
        data-bg={useBg}
        background={{ image: small ? `url(${optimizeImageSrc(useBg)}&w=1024)` : `url(${optimizeImageSrc(useBg)})` }}
      >
        <Container
          pad={{ horizontal: horizontalPad, top: topPad, bottom: bottomPad }}
        >
          {children}
        </Container>
      </Box>
    );
  }

  return (
    <Box overflow="hidden" background={useBg}>
      <Container
        pad={{ horizontal: horizontalPad, top: topPad, bottom: bottomPad }}
      >
        {children}
      </Container>
    </Box>
  );
};

ContentSectionWrapper.propTypes = {
  small: PropTypes.bool.isRequired,
  noPadHorizontal: PropTypes.bool,
  lessPadHorizontal: PropTypes.bool,
  zeroPad: PropTypes.bool,
  noPadTop: PropTypes.bool,
  noPadBottom: PropTypes.bool,
  lessPadBottom: PropTypes.bool,
  lessPadTop: PropTypes.bool,
  background: PropTypes.string.isRequired,
  mobileBackground: PropTypes.string,
  children: PropTypes.node,
};

const ContentSections = ({
  small,
  industries = null,
  articles = null,
  scrollers,
  ...rest
}) => {
  const renderScroller = (key, scrollerContent) => {
    switch (key) {
      case 'introText':
        return <IntroTextScroller small={small} {...scrollerContent} {...rest} />;

      case 'problemStatement':
        return <ProblemStatementScroller small={small} {...scrollerContent} {...rest} />;

      case 'tabs':
        return (
          <TabScroller small={small} {...scrollerContent} {...rest} />
        );

      case 'listCards':
        return <ListCardsScroller small={small} {...scrollerContent} {...rest} />;

      case 'plainCards':
        return <CardsScroller isPlain small={small} {...scrollerContent} {...rest} />;

      case 'columnCards':
      case 'columnCardsDupe':
        return <CardsScroller small={small} {...scrollerContent} {...rest} />;

      case 'demoRow':
        return <DemoRowScroller small={small} {...scrollerContent} {...rest} />;
      
      case 'multiDemoRow':
        return <MultiDemoRowScroller small={small} {...scrollerContent} {...rest} />;

      case 'dualImageRow':
        return <DemoRowScroller dualImage small={small} {...scrollerContent} {...rest} />;

      case 'controlsVideo':
        return <VideoScroller small={small} {...scrollerContent} {...rest} />;

      case 'industries':
        return (
          <IndustriesScroller
            small={small}
            industries={industries}
            {...scrollerContent}
            {...rest}
          />
        );

      case 'slider':
        return <SliderScroller small={small} {...scrollerContent} {...rest} />;

      case 'team':
        return <TeamScroller small={small} {...scrollerContent} {...rest} />;

      case 'illustrationCards':
        return <IllustrationCardsScroller small={small} {...scrollerContent} {...rest} />;

      case 'techStack':
        return <TechStackScroller small={small} {...scrollerContent} {...rest} />;

      case 'iconColumns':
        return <IconsScroller layout="column" small={small} {...scrollerContent} {...rest} />;

      case 'iconRow':
        return <IconsScroller layout="row" small={small} {...scrollerContent} {...rest} />;

      case 'iconListRow':
        return <IconsScroller withList layout="row" small={small} {...scrollerContent} {...rest} />;

      case 'trending':
        return <TrendingScroller small={small} {...scrollerContent} {...rest} />;

      case 'resources':
        return (
          <ResourcesScroller small={small} articles={articles} {...scrollerContent} {...rest} />
        );

      case 'illustrationDupe':
      case 'illustration':
        return <IllustrationScroller small={small} {...scrollerContent} {...rest} />;

      case 'services':
        return <ServicesScroller small={small} {...scrollerContent} {...rest} />;

      case 'socialMedia':
        return <SocialMediaScroller small={small} {...scrollerContent} {...rest} />;

      case 'infoRow':
        return <InfoRowScroller small={small} {...scrollerContent} {...rest} />;

      case 'careers':
        return <CareersScroller small={small} {...scrollerContent} {...rest} />;

      case 'marqueeText':
        return <MarqueeTextScroller small={small} {...scrollerContent} {...rest} />;
      
      case 'cta':
        return <CTAScroller small={small} {...scrollerContent} {...rest} />;
      
      case 'faq':
        return <FAQScroller small={small} {...scrollerContent} {...rest} />;

      case 'iconCards':
        return <IconCardsScroller small={small} {...scrollerContent} {...rest} />;
      
      case 'mobileCarousel':
        return <MobileCarousel small={small} {...scrollerContent} {...rest} />;

      default:
        return null;
    }
  };

  return (
    <>
      {Object.keys(scrollers).map((key) => (
        <ContentSectionWrapper
          key={key}
          small={small}
          noPadHorizontal={key === 'slider' || key === 'iconCards' || (scrollers[key].noPadHorizontal && scrollers[key].noPadHorizontal === true)}
          zeroPad={key === 'trending' || key === 'marqueeText' || (scrollers[key].zeroPad && scrollers[key].zeroPad === true)}
          noPadTop={scrollers[key].noPadTop && scrollers[key].noPadTop === true}
          noPadBottom={scrollers[key].noPadBottom && scrollers[key].noPadBottom === true}
          lessPadHorizontal={scrollers[key].lessPadHorizontal && scrollers[key].lessPadHorizontal === true}
          lessPadTop={scrollers[key].lessPadTop && scrollers[key].lessPadTop === true}
          lessPadBottom={scrollers[key].lessPadBottom && scrollers[key].lessPadBottom === true}
          background={scrollers[key].sectionBg}
          mobileBackground={scrollers[key].mobileSectionBg}
        >
          {renderScroller(key, scrollers[key])}
        </ContentSectionWrapper>
      ))}
    </>
  );
};

ContentSections.propTypes = {
  small: PropTypes.bool.isRequired,
  industries: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
  })),
  articles: PropTypes.arrayOf(PropTypes.any),
  scrollers: PropTypes.shape({
    introText: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      subHeader: PropTypes.string.isRequired,
      buttonColor: PropTypes.string.isRequired,
      iconBg: PropTypes.string.isRequired,
      iconBorderColor: PropTypes.string.isRequired,
      borderImage: PropTypes.string.isRequired,
      anchorButtons: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      })).isRequired,
    }),
    problemStatement: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      mobileSectionBg: PropTypes.string,
      header: PropTypes.string.isRequired,
      paragraph: PropTypes.arrayOf(PropTypes.string.isRequired),
      textColor: PropTypes.string.isRequired,
    }),
    services: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      firstSectionHeader: PropTypes.string.isRequired,
      secondSectionHeader: PropTypes.string.isRequired,
      primaryTextColor: PropTypes.string.isRequired,
      headerColor: PropTypes.string.isRequired,
      firstSectionParagraph: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      secondSectionItems: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        industryKey: PropTypes.string.isRequired,
      })).isRequired,
    }),
    demoRow: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      headerColor: PropTypes.string.isRequired,
      subHeader: PropTypes.string,
      textColor: PropTypes.string,
      reverse: PropTypes.bool,
      loaderImage: PropTypes.string.isRequired,
      animation: PropTypes.shape({
        mp4: PropTypes.string.isRequired,
        webm: PropTypes.string.isRequired,
      }),
      approach: PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        hoverColor: PropTypes.string.isRequired,
      }),
    }),
    multiDemoRow: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      headerColor: PropTypes.string,
      sections: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.string.isRequired,
        subHeader: PropTypes.string.isRequired,
        reverse: PropTypes.bool,
        loaderImage: PropTypes.string.isRequired,
        layout: PropTypes.string,
        animation: PropTypes.shape({
          mp4: PropTypes.string.isRequired,
          webm: PropTypes.string.isRequired,
        }),
        approach: PropTypes.shape({
          text: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
          hoverColor: PropTypes.string.isRequired,
        }),
      })).isRequired,
    }),
    tabs: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      header: PropTypes.string,
      subHeader: PropTypes.string,
      textColor: PropTypes.string.isRequired,
      tabHighlight: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        uid: PropTypes.string.isRequired,
        content: PropTypes.shape({
          title: PropTypes.string,
          tabTitle: PropTypes.string,
          headline: PropTypes.string.isRequired,
          text: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            body: PropTypes.string.isRequired,
          })),
          list: PropTypes.arrayOf(PropTypes.string.isRequired),
          image: PropTypes.string,
          lazyAnimation: PropTypes.node,
          animation: PropTypes.shape({
            mp4: PropTypes.node.isRequired,
            webm: PropTypes.node.isRequired,
          }),
          tabApproach: PropTypes.shape({
            text: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
            hoverColor: PropTypes.string.isRequired,
          }),
          lowerSection: PropTypes.shape({
            headline: PropTypes.string.isRequired,
            subHeadline: PropTypes.string,
            titleHighlight: PropTypes.string,
            boxShadow: PropTypes.string.isRequired,
            cardBg: PropTypes.string.isRequired,
            items: PropTypes.arrayOf(PropTypes.shape({
              title: PropTypes.string.isRequired,
              text: PropTypes.string.isRequired,
            })).isRequired,
          }),
        }),
      }).isRequired).isRequired,
    }),
    listCards: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      header: PropTypes.string,
      headerImage: PropTypes.string,
      subHeader: PropTypes.string,
      headerColor: PropTypes.string.isRequired,
      firstListConfig: PropTypes.shape({
        boxBg: PropTypes.string.isRequired,
        boxShadow: PropTypes.string,
        border: PropTypes.string,
        textColor: PropTypes.string.isRequired,
        listIcon: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
          title: PropTypes.string.isRequired,
          text: PropTypes.string,
        })).isRequired,
      }).isRequired,
      secondListConfig: PropTypes.shape({
        boxBg: PropTypes.string.isRequired,
        boxShadow: PropTypes.string,
        border: PropTypes.string,
        textColor: PropTypes.string.isRequired,
        listIcon: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
          title: PropTypes.string.isRequired,
          text: PropTypes.string,
        })).isRequired,
      }).isRequired,
    }),
    plainCards: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      title: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      subTitle: PropTypes.string,
      titleColor: PropTypes.string.isRequired,
      cardTextColor: PropTypes.string.isRequired,
      cardHeaderColor: PropTypes.string,
      itemAlign: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        header: PropTypes.string,
        text: PropTypes.string,
      })).isRequired,
      approach: PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        hoverColor: PropTypes.string.isRequired,
      }),
    }),
    columnCards: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      title: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      subTitle: PropTypes.string,
      titleColor: PropTypes.string.isRequired,
      cardBg: PropTypes.string.isRequired,
      boxShadow: PropTypes.string.isRequired,
      hoverColor: PropTypes.string.isRequired,
      cardTextColor: PropTypes.string.isRequired,
      cardHeaderColor: PropTypes.string,
      cardWidth: PropTypes.string,
      itemAlign: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        header: PropTypes.string,
        text: PropTypes.string,
        industryKey: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.string.isRequired),
      })).isRequired,
      approach: PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        hoverColor: PropTypes.string.isRequired,
      }),
    }),
    slider: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      titleColor: PropTypes.string.isRequired,
      subTitle: PropTypes.string,
      slideTextColor: PropTypes.string.isRequired,
      slideBoxShadow: PropTypes.string.isRequired,
      slideBg: PropTypes.string.isRequired,
      highlight: PropTypes.string.isRequired,
      slides: PropTypes.arrayOf(PropTypes.objectOf({
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })).isRequired,
    }),
    team: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        groupTitle: PropTypes.string.isRequired,
        members: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      })).isRequired,
    }),
    illustration: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      subHeader: PropTypes.string,
      headerColor: PropTypes.string.isRequired,
      listTitleColor: PropTypes.string.isRequired,
      listTextColor: PropTypes.string.isRequired,
      listIcon: PropTypes.string,
      illustration: PropTypes.string.isRequired,
      firstColumn: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })).isRequired,
      secondColumn: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })),
    }),
    illustrationCards: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      header: PropTypes.string,
      altHeader: PropTypes.shape({
        text: PropTypes.string.isRequired,
        highlightText: PropTypes.string.isRequired,
        highlightColor: PropTypes.string.isRequired,
      }),
      headerColor: PropTypes.string.isRequired,
      headerAlign: PropTypes.string.isRequired,
      cardTitleColor: PropTypes.string,
      cardTextColor: PropTypes.string.isRequired,
      cardBg: PropTypes.string.isRequired,
      boxShadow: PropTypes.string,
      illustration: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })).isRequired,
      approach: PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        hoverColor: PropTypes.string.isRequired,
      }),
    }),
    techStack: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      headerColor: PropTypes.string.isRequired,
      listIcon: PropTypes.string.isRequired,
      cardBg: PropTypes.string.isRequired,
      cardTitleColor: PropTypes.string.isRequired,
      cardTextColor: PropTypes.string.isRequired,
      boxShadow: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        listItems: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      })).isRequired,
    }),
    iconColumns: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      header: PropTypes.string,
      iconAlign: PropTypes.string,
      textAlign: PropTypes.string,
      desktopWidth: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        title: PropTypes.string.isRequired,
        text: PropTypes.string,
      })).isRequired,
      approach: PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        hoverColor: PropTypes.string.isRequired,
      }),
    }),
    iconRow: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      header: PropTypes.string,
      iconAlign: PropTypes.string,
      textAlign: PropTypes.string,
      desktopWidth: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string,
      })).isRequired,
      approach: PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        hoverColor: PropTypes.string.isRequired,
      }),
    }),
    iconListRow: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      header: PropTypes.string,
      iconAlign: PropTypes.string,
      textAlign: PropTypes.string,
      desktopWidth: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        list: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      })).isRequired,
      approach: PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        hoverColor: PropTypes.string.isRequired,
      }),
    }),
    trending: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      header: PropTypes.string,
      headerColor: PropTypes.string,
      subHeader: PropTypes.string,
      subHeaderColor: PropTypes.string,
      dataTextColor: PropTypes.string.isRequired,
      firstDataTitle: PropTypes.string.isRequired,
      firstData: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      secondDataTitle: PropTypes.string.isRequired,
      secondData: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }),
    resources: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      headerColor: PropTypes.string,
      cardBg: PropTypes.string.isRequired,
      boxShadow: PropTypes.string.isRequired,
      hoverShadow: PropTypes.string.isRequired,
      textHighlightColor: PropTypes.string.isRequired,
      articleUids: PropTypes.arrayOf(PropTypes.string.isRequired),
      approachText: PropTypes.string,
      approach: PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        hoverColor: PropTypes.string.isRequired,
      }),
    }),
    industries: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      headerColor: PropTypes.string,
      approachText: PropTypes.string,
      industryCards: PropTypes.arrayOf(PropTypes.shape({
        industryKey: PropTypes.string.isRequired,
        image: PropTypes.string,
        title: PropTypes.string.isRequired,
        headline: PropTypes.string.isRequired,
      })).isRequired,
      textColor: PropTypes.string.isRequired,
      textHighlight: PropTypes.string.isRequired,
      approach: PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        hoverColor: PropTypes.string.isRequired,
      }),
    }),
    socialMedia: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      highlightColor: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      boxShadow: PropTypes.string.isRequired,
      linkedInPosts: PropTypes.arrayOf(PropTypes.shape({
        embedUrl: PropTypes.string.isRequired,
        postUrl: PropTypes.string.isRequired,
      }).isRequired).isRequired,
      approach: PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        hoverColor: PropTypes.string.isRequired,
      }),
    }),
    infoRow: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      subHeader: PropTypes.arrayOf(PropTypes.string.isRequired),
      headerColor: PropTypes.string.isRequired,
      listTitleColor: PropTypes.string.isRequired,
      listTextColor: PropTypes.string.isRequired,
      listIcon: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })).isRequired,
    }),
    marqueeText: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
    cta: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string,
      textColor: PropTypes.string.isRequired,
      approach: PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        hoverColor: PropTypes.string.isRequired,
      }),
    }),
    faq: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      titleColor: PropTypes.string.isRequired,
      subTitle: PropTypes.string,
      textColor: PropTypes.string.isRequired,
      hoverHighlight: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({
        question: PropTypes.string.isRequired,
        answer: PropTypes.string.isRequired,
      })).isRequired,
    }),
    iconCards: PropTypes.shape({
      sectionBg: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      titleColor: PropTypes.string.isRequired,
      titleCardBg: PropTypes.string.isRequired,
      cardTextColor: PropTypes.string.isRequired,
      cardBgColor: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }).isRequired).isRequired,
    }),
  }).isRequired,
};

export default ContentSections;
