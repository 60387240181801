import React from 'react';
import styled from 'styled-components';

import { Heading, Paragraph, Anchor } from 'grommet';
import { LazyResponsiveImageLoader } from '@/components/Control';

const StyledHeader = styled(Heading)`
  word-break: keep-all;
  font-weight: 300;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
`;

const StyledStrong = styled.strong`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
`;

const StyledLogo = styled((props) => <LazyResponsiveImageLoader {...props} />)`
  && {
    max-width: 30rem;
    width: 100%;
    margin-top: 3rem;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
`;

const StyledIcon = styled((props) => <LazyResponsiveImageLoader {...props} />)`
  && {
    width: 100%;
    height: 100%;
    max-width: 16.55rem;

    @media (max-width: 70em) and (min-width: 48.0625em) {
      width: 26vw;
      min-width: 225px;
    }

    @media (max-width: 48em) {
      max-width: 225px;
    }
  }
`;

const StyledIconTitle = styled(StyledHeader)`
  && {
    letter-spacing: normal;
  }
`;

const StyledIconDescription = styled(Paragraph)`
  max-width: 100%;
  letter-spacing: normal;
`;

const StyledContactTitle = styled(Heading)`
  font-weight: 800;
  letter-spacing: 0.15rem;
`;

const StyledContactAnchor = styled(Anchor)`
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.15rem;
`;

export {
  StyledHeader,
  StyledStrong,
  StyledLogo,
  StyledIconTitle,
  StyledIcon,
  StyledIconDescription,
  StyledContactTitle,
  StyledContactAnchor,
};
