'use client';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import {
  Box, Text, Image, Heading,
} from 'grommet';

import { useClientMediaQuery } from '@/lib/clientMediaQuery';
import { optimizeImageSrc } from '@/helpers/util';
import {
  Container, FullHeightBox, AppButton, AppAnchor, Spinning,
} from '@/components/Control';
import { paths } from '@/components/configs';
import { PostCard } from '../ServiceDynamic/ContentSections/ResourcesScroller';
import SocialMediaScroller from '../ServiceDynamic/ContentSections/SocialMediaScroller';
import {
  StyledHeading,
  StyledCardImage,
  StyledFeaturedContainer,
  StyledCategoryButton,
  StyledPagination,
} from './StyledBlogPage';


const StyledArticleTitle = styled(Heading)`
  font-weight: 600;
`;

const SeriesCard = ({
  small,
  seriesData,
  textColor,
}) => {
  const orderedPosts = _.orderBy(seriesData, ['metadata[seriesorder]'], ['asc']);

  return (
    <Box
      direction="column"
      gap="1rem"
      width="100%"
      justify="between"
    >
      <Box round={{ size: '8px' }} width="100%" height={small ? '18rem' : '21rem'} overflow="hidden">
        <Image
          src={optimizeImageSrc(seriesData[0].metadata.seriescover.imgix_url)}
          alt={`${seriesData[0].metadata.seriestitle} series image`}
          fit="cover"
        />
      </Box>
      <Box flex direction="column">
        <Box direction="column" gap="1rem">
          <StyledArticleTitle
            size={small ? '1.2rem' : '1.125rem'}
            level={3}
            color={textColor}
            margin="none"
          >
            {seriesData[0].metadata.seriestitle}
          </StyledArticleTitle>
          <Box wrap direction="row" pad={{ bottom: '1rem' }}>
            {orderedPosts?.map(({ slug }, i) => (
              <StyledCategoryButton
                plain
                key={slug}
                href={paths.blogPost.replace(':uid', slug)}
                label={`Part ${i + 1}`}
                color={textColor}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

SeriesCard.propTypes = {
  small: PropTypes.bool.isRequired,
  seriesData: PropTypes.arrayOf(PropTypes.shape({
    metadata: PropTypes.shape({
      articlecontent: PropTypes.string.isRequired,
      seriestitle: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        imgix_url: PropTypes.string.isRequired,
      }),
    }),
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  textColor: PropTypes.string.isRequired,
};

const PaginatedSection = ({
  small,
  sectionTitle,
  textColor,
  linkHighlightColor,
  displayCategory = null,
  allCategories = null,
  handleFilter = null,
  verticalDisplayMap = null,
  displayData = null,
  seriesKeys = null,
  groupedSeries = null,
}) => {
  const postsPerPage = small ? 4 : 8;
  const [currentData, setCurrentData] = React.useState(displayData?.slice(0, postsPerPage));
  const [indices, setIndices] = React.useState([0, postsPerPage]);

  const withFilters = displayCategory && allCategories && handleFilter;
  const isSeries = seriesKeys && groupedSeries;

  React.useEffect(() => {
    if (!isSeries) {
      setCurrentData(displayData.slice(0, postsPerPage));
    }
  }, [displayData, isSeries, postsPerPage]);

  const handleChange = ({ startIndex, endIndex }) => {
    const nextData = displayData.slice(startIndex, endIndex);
    setCurrentData(nextData);
    setIndices([startIndex, Math.min(endIndex, displayData.length)]);
  };

  const showPagination = (displayData?.length > postsPerPage) && !isSeries;

  const renderPostData = (data) => {
    if (isSeries) return (
      <>
        {seriesKeys && seriesKeys.length >= 1 && seriesKeys.map((seriesKey) => (
          <Box key={seriesKey} pad={{ right: small ? '0' : '2rem', vertical: '1rem' }} width={small ? '100%' : '50%'}>
            <SeriesCard
              small={small}
              seriesData={groupedSeries[seriesKey]}
              textColor={textColor}
            />
          </Box>
        ))}
      </>
    );

    return (
      <>
        {data && data.map((post) => (
          <Box key={post.slug} pad={{ right: small ? '0' : '2rem', vertical: '1rem' }} width={small ? '100%' : '25%'}>
            <PostCard
              small={small}
              post={post}
              cardWidth="100%"
              cardHeight={small ? '23.5rem' : '21rem'}
              textColor={textColor}
              textHighlightColor={linkHighlightColor}
            />
          </Box>
        ))}
      </>
    );
  };

  return (
    <Box>
      <StyledHeading size="2rem" weight={600} color={textColor} textAlign="start">
        {sectionTitle}
      </StyledHeading>
      {withFilters && (
        <Box wrap direction="row" pad={{ top: '1rem' }}>
          <StyledCategoryButton
            plain
            active={displayCategory === 'all'}
            onClick={() => handleFilter('all')}
            label="All"
            color={textColor}
          />
          {allCategories.length >= 1 && allCategories.map((category) => (
            <StyledCategoryButton
              plain
              key={category}
              active={displayCategory === category}
              onClick={() => handleFilter(category)}
              label={verticalDisplayMap[category]}
              color={textColor}
            />
          ))}
        </Box>
      )}
      <Box direction="column" pad={{ top: '1rem' }}>
        <Box
          wrap={!small}
          direction={small ? 'column' : 'row'}
          justify={small ? 'center' : 'start'}
          align="center"
        >
          {renderPostData(currentData)}
        </Box>
        {showPagination && (
          <Box justify="center" align="center" pad={{ vertical: '1rem' }}>
            <StyledPagination
              numberItems={displayData.length}
              step={postsPerPage}
              onChange={handleChange}
              size="small"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

PaginatedSection.propTypes = {
  small: PropTypes.bool.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  linkHighlightColor: PropTypes.string.isRequired,
  displayCategory: PropTypes.string.isRequired,
  allCategories: PropTypes.arrayOf(PropTypes.string.isRequired),
  handleFilter: PropTypes.func,
  verticalDisplayMap: PropTypes.objectOf(PropTypes.any.isRequired),
  displayData: PropTypes.arrayOf(PropTypes.shape({
    metadata: PropTypes.shape({
      articlecontent: PropTypes.string.isRequired,
      seriestitle: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        imgix_url: PropTypes.string.isRequired,
      }),
    }),
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  seriesKeys: PropTypes.arrayOf(PropTypes.string.isRequired),
  groupedSeries: PropTypes.objectOf(PropTypes.any.isRequired),
};

const BlogPage = ({
  blogPosts = null,
  config: {
    articlesPageBg,
    articlesAltPageBg,
    articlesBgImage,
    articlesAbstractBg,
    articlesTextColor,
    articlesHighlight,
    articlesAltHighlight,
    articlesLinkHighlight,
    articlesBoxShadow,
    articlesHoverShadow,
    articlesFollowers,
    linkedInPosts,
    verticalDisplayMap,
  },
}) => {
  const [displayPosts, setDisplayPosts] = React.useState([]);
  const [displayCategory, setDisplayCategory] = React.useState('all');
  const [featuredDate, setFeaturedDate] = React.useState('');
  const small = useClientMediaQuery('(max-width: 768px)');

  React.useEffect(() => {
    if (blogPosts) {
      const date = new Date(blogPosts[0].published_at);
      const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

      setFeaturedDate(date.toLocaleDateString('en-US', dateOptions));
    }
  }, [blogPosts]);

  React.useEffect(() => {
    if (blogPosts && displayPosts.length === 0) {
      const filteredPosts = _.filter(blogPosts, (({ metadata }) => metadata.category !== 'guide' && metadata.category !== 'series'));
      setDisplayPosts(filteredPosts);
    }
  }, [blogPosts, displayPosts]);

  React.useEffect(() => {
    if (displayCategory === 'all' && blogPosts) {
      const allPosts = _.filter(blogPosts, (({ metadata }) => metadata.category !== 'guide' && metadata.category !== 'series'));
      setDisplayPosts(allPosts);
    } else if (blogPosts) {
      const categoryPosts = _.filter(blogPosts, (({ metadata }) => metadata.category === displayCategory));
      setDisplayPosts(categoryPosts);
    }
  }, [displayCategory, blogPosts]);

  if (!blogPosts) {
    return (
      <FullHeightBox pad={{ top: '5.2rem' }} background={articlesPageBg} justify="center" align="center">
        <Spinning color="white" size={small ? 'large' : 'xlarge'} />
      </FullHeightBox>
    );
  }

  const handleFilter = (category) => setDisplayCategory(category);

  const guidePosts = blogPosts && _.filter(blogPosts, (({ metadata }) => metadata.category === 'guide'));
  const seriesPosts = blogPosts && _.filter(blogPosts, (({ metadata }) => metadata.category === 'series'));
  const groupedSeries = seriesPosts && _.groupBy(seriesPosts, (({ metadata }) => metadata.serieskey));
  const seriesKeys = groupedSeries && Object.keys(groupedSeries);

  let allCategories = [];
  const blogCategories = blogPosts && blogPosts.map(({ metadata }) => {
    if (allCategories.indexOf(metadata.category) === -1
      && metadata.category !== 'guide' && metadata.category !== 'series') {
      allCategories.push(metadata.category);
    }
  });

  return (
    <Container fill pad={{ top: '5.2rem' }} background={articlesPageBg} overflow="hidden">
      <Box fill="vertical" direction="column">
        <Box
          justify="center"
          align="center"
          pad={{ horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)', bottom: '3.5rem', top: '3.5rem' }}
          background={{
            image: `url(${articlesBgImage})`,
            color: 'white',
          }}
        >
          <StyledFeaturedContainer
            boxShadow={articlesBoxShadow}
            hoverShadow={articlesHoverShadow}
            path={paths.blogPost.replace(':uid', blogPosts[0].slug)}
          >
            <Box
              direction={small ? 'column-reverse' : 'row'}
              height={{ min: '24rem' }}
              width="100%"
              background={articlesAltPageBg}
              round="0.625rem"
            >
              <Box direction="column" gap="1rem" width={small ? '100%' : '55%'} pad={small ? '1rem' : '3.5rem'}>
                <Text uppercase size="1rem" color={articlesAltHighlight} textAlign="start" style={{ letterSpacing: '0.235px' }} weight={600}>
                  Featured Blog
                </Text>
                <Box width={small ? '100%' : '85%'}>
                  <Text size={small ? '1.75rem' : '2rem'} weight={600} color={articlesTextColor} textAlign="start">
                    {blogPosts[0].title}
                  </Text>
                </Box>
                <Box direction="row" gap="0.75rem" align="center">
                  <Box
                    background="white"
                    justify="center"
                    align="center"
                    width="3rem"
                    height="3rem"
                    round="50%"
                    border={{ style: 'solid', side: 'all', color: '#D0D0D0' }}
                    pad="0.45rem"
                  >
                    <Image
                      fit="contain"
                      src="/images/logo/logo-image-only-color.svg"
                      alt="Redflag AI logo"
                      width="3rem"
                      height="3rem"
                    />
                  </Box>
                  <Box direction="column" gap="0.35rem">
                    <Text size="1.2rem" weight={500} color={articlesTextColor} textAlign="start">
                      {`${blogPosts[0].metadata.firstname} ${blogPosts[0].metadata.lastname}`}
                    </Text>
                    <Text size="1rem" color="#9C9C9C" textAlign="start">
                      {`${blogPosts[0].metadata.readtime} | Last updated: ${featuredDate}`}
                    </Text>
                  </Box>
                </Box>
                <Box width="100%" align="start" pad={{ top: '1.5rem' }}>
                  <AppButton
                    overrideHover
                    level="dynamicLarge"
                    label="Read Now"
                    alignSelf="start"
                    textColor="white"
                    width="7.5rem"
                    bgColor={articlesHighlight}
                    path={paths.blogPost.replace(':uid', blogPosts[0].slug)}
                  />
                </Box>
              </Box>
              <Box round={{ size: '0.625rem', corner: small ? 'top' : 'right' }} width={small ? '100%' : '45%'} overflow="hidden" pad={small ? '0.85rem' : '1rem'}>
                <StyledCardImage
                  data-bg={blogPosts[0].metadata.image.imgix_url}
                  background={{ image: `url(${optimizeImageSrc(blogPosts[0].metadata.image.imgix_url)})` }}
                  overflow="hidden"
                  height={small ? { min: '16rem' } : '100%'}
                  width="100%"
                  bgSize={small ? '100%' : '145%'}
                />
              </Box>
            </Box>
          </StyledFeaturedContainer>
        </Box>
        <Box direction="column" background={articlesAltPageBg} gap="3.5rem" pad={{ horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)', vertical: '3.5rem' }}>
          <PaginatedSection
            key="blogs"
            small={small}
            textColor={articlesTextColor}
            sectionTitle="Blogs"
            linkHighlightColor={articlesLinkHighlight}
            displayCategory={displayCategory}
            allCategories={allCategories}
            handleFilter={handleFilter}
            verticalDisplayMap={verticalDisplayMap}
            displayData={displayPosts}
          />
          <PaginatedSection
            key="series"
            small={small}
            textColor={articlesTextColor}
            sectionTitle="Blog Series"
            linkHighlightColor={articlesLinkHighlight}
            seriesKeys={seriesKeys}
            groupedSeries={groupedSeries}
          />
          <PaginatedSection
            key="guides"
            small={small}
            textColor={articlesTextColor}
            sectionTitle="Guides"
            linkHighlightColor={articlesLinkHighlight}
            displayData={guidePosts}
          />
        </Box>
      </Box>
      <Box
        small={small}
        background={{
          image: small ? `url(${articlesAbstractBg}&w=1024)` : `url(${articlesAbstractBg})`,
          color: articlesAltPageBg,
        }}
        direction="column"
        align="center"
        pad={{ vertical: '3.5rem' }}
        gap="2rem"
        highlight={articlesHighlight}
      >
        <Box direction="column" gap="2rem">
          <Box width="100%" pad={{ horizontal: small ? 'var(--vw5p725)' : '0rem' }}>
            <Text size="2rem" weight={600} color="white" textAlign="center">
              Let&apos;s talk about how Redflag AI can help your business
            </Text>
          </Box>
          <Text size="1rem" color="white" textAlign="center">
            Pick a time to chat with a team member today.
          </Text>
        </Box>
        <AppAnchor path={paths.contact}>
          <AppButton
            overrideHover
            level="dynamicLarge"
            label="Book a Demo"
            alignSelf="start"
            fontWeight={600}
            textColor={articlesHighlight}
            bgColor="white"
          />
        </AppAnchor>
      </Box>
      <Box direction="column" gap="1rem" background={articlesPageBg} pad={{ horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)', vertical: '3.5rem' }}>
        <SocialMediaScroller
          small={small}
          header="More from our LinkedIn"
          linkedInPosts={linkedInPosts}
          textColor={articlesTextColor}
          boxShadow={articlesBoxShadow}
          hoverShadow={articlesHoverShadow}
          highlightColor={articlesHighlight}
          followers={articlesFollowers}
        />
      </Box>
    </Container>
  );
};

BlogPage.propTypes = {
  config: PropTypes.shape({
    articlesPageBg: PropTypes.string.isRequired,
    articlesAltPageBg: PropTypes.string.isRequired,
    articlesBgImage: PropTypes.string,
    articlesBg: PropTypes.string.isRequired,
    articlesAbstractBg: PropTypes.string.isRequired,
    articlesTextColor: PropTypes.string.isRequired,
    articlesHighlight: PropTypes.string.isRequired,
    articlesAltHighlight: PropTypes.string.isRequired,
    articlesLinkHighlight: PropTypes.string.isRequired,
    articlesBoxShadow: PropTypes.string.isRequired,
    articlesHoverShadow: PropTypes.string.isRequired,
    articlesFollowers: PropTypes.string.isRequired,
    linkedInPosts: PropTypes.arrayOf(PropTypes.shape({
      postUrl: PropTypes.string.isRequired,
      postText: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      hashtags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      linkData: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired).isRequired,
    verticalDisplayMap: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  }).isRequired,
  blogPosts: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      articlecontent: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      readtime: PropTypes.string.isRequired,
      published: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      serieskey: PropTypes.string,
      seriestitle: PropTypes.string,
      seriesorder: PropTypes.number,
      seriescover: PropTypes.string,
      trending: PropTypes.bool.isRequired,
      thankyoudisplay: PropTypes.bool.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        imgix_url: PropTypes.string.isRequired,
      }).isRequired,
      imagelayout: PropTypes.string.isRequired,
    }).isRequired,
  })),
};

export default BlogPage;
