import React from 'react';
import styled from 'styled-components';

import {
  Box, Text, Heading, Button, Pagination,
} from 'grommet';

import { AppAnchor } from '@/components/Control';

const StyledHeading = styled(Text)`
  line-height: 1.2;
  letter-spacing: normal;
`;

const StyledPostAnchor = styled((props) => <AppAnchor {...props} />)`
  text-decoration: none;
  background-color: ${({ background }) => background};
  border-radius: 2rem;
  margin: 1.5rem;
  height: 20rem;
  width: 15rem;
  box-shadow: ${({ boxShadow }) => boxShadow};
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    text-decoration: none;
    box-shadow: ${({ hoverShadow }) => hoverShadow};
  }
`;

const StyledCardImage = styled(Box)`
  background-repeat: no-repeat;
  background-size: ${({ bgSize }) => bgSize || '100%'};
  background-position: center;
  border-radius: ${({ borderRadius }) => borderRadius || '6px'};
`;

const StyledFeaturedContainer = styled((props) => <AppAnchor {...props} />)`
  text-decoration: none;
  box-shadow: ${({ boxShadow }) => boxShadow};
  border-radius: 0.625rem;
  border: ${({ noBorder }) => noBorder ? 'none' : '1px solid #D0D0D0'};
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;

  &:hover {
    text-decoration: none;
    box-shadow: ${({ hoverShadow }) => hoverShadow};
  }
`;

const StyledCategoryButton = styled(Button)`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.75rem;
  background-color: #E9EBED;
  border-radius: 10px;
  padding: 0.5rem 0.6rem;
  font-weight: 600;
  font-size: 1rem;
  border: ${({ active }) => active ? '1px solid #3C3C3C' : '1px solid rgba(0,0,0,0)'};

  &:hover {
    border: 1px solid #3C3C3C;
  }
`;

const StyledPagination = styled(Pagination)`
  li {
    height: 25px;
    width: 25px;
    min-width: 0;
  }

  button {
    color: #3C3C3C;
    border-radius: 3px;
  }

  button[aria-current="page"] {
    background: white;
    border: 1px solid #174EA6;
  }

  svg {
    height: 14px;
    width: 14px;
  }

  button:not(:disabled) {
    svg {
      fill: #004FFE;
      stroke: #004FFE;
    }
  }
`;

export {
  StyledHeading,
  StyledPostAnchor,
  StyledCardImage,
  StyledFeaturedContainer,
  StyledCategoryButton,
  StyledPagination,
};
