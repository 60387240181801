import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box, Text } from 'grommet';
import { FormNext, FormPrevious } from 'grommet-icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {
  StyledUseCaseSlideContainer, StyledIconButton, StyledSmallHeader,
} from './StyledContentSections';

const renderIconButtons = (
  activeSlide, totalLength, nextSlide, prevSlide, color, highlight,
) => (
  <Box direction="row" gap="1rem" justify="center">
    {(activeSlide !== 0) && (
      <StyledIconButton onClick={() => prevSlide()} color={color} highlight={highlight}>
        <FormPrevious color={color} size="3rem" />
      </StyledIconButton>
    )}
    {(activeSlide !== (totalLength - 1)) && (
      <StyledIconButton onClick={() => nextSlide()} color={color} highlight={highlight}>
        <FormNext color={color} size="3rem" />
      </StyledIconButton>
    )}
  </Box>
);

const UseCaseHeader = ({
  activeSlide, isSmall, contentLength, color,
}) => (
  <Box pad={{ left: isSmall ? '0.5rem' : '3rem' }}>
    <Text color={color} size="1.15rem" weight={700}>
      {`0${activeSlide + 1}`}
      /
      {`0${contentLength}`}
    </Text>
  </Box>
);

UseCaseHeader.propTypes = {
  activeSlide: PropTypes.number.isRequired,
  isSmall: PropTypes.bool.isRequired,
  contentLength: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

const UseCaseSlider = ({
  small, textColor, boxShadow, highlight, slideBg, content,
}) => {
  const [activeSlide, setActiveSlide] = React.useState(0);

  const nextSlide = () => { setActiveSlide(activeSlide + 1); };
  const prevSlide = () => { setActiveSlide(activeSlide - 1); };

  return (
    <Box flex justify="center" align="center">
      <Box width={small ? '30rem' : '40rem'} pad="1rem">
        <UseCaseHeader
          activeSlide={activeSlide}
          isSmall={small}
          contentLength={content.length}
          color={textColor}
        />
        <Carousel
          emulateTouch
          centerMode
          centerSlidePercentage={100}
          isSmall={small}
          showArrows={false}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          selectedItem={activeSlide}
          onChange={(index) => setActiveSlide(index)}
        >
          {content.map((dt, i) => (
            <Box flex key={uniqid()} pad={small ? { top: '1.5rem', bottom: '2.5rem', horizontal: '1.25rem' } : { top: '1.5rem', bottom: '2.5rem', horizontal: '2.5rem' }}>
              <StyledUseCaseSlideContainer
                isSmall={small}
                isActive={i === activeSlide}
                boxShadow={boxShadow}
                bgColor={slideBg}
                pad={{ horizontal: '1.5rem', top: '1.5rem' }}
              >
                <Box
                  pad={{ bottom: '0.35rem' }}
                  margin={{ bottom: '0.35rem' }}
                  width={small ? '100%' : '75%'}
                  border={{
                    color: (i === activeSlide ? highlight : textColor),
                    size: '1px',
                    style: 'solid',
                    side: 'bottom',
                  }}
                >
                  <StyledSmallHeader
                    color={(i === activeSlide ? highlight : textColor)}
                    size="1.75rem"
                    weight={800}
                    textAlign={small ? 'center' : 'start'}
                  >
                    {dt.title}
                  </StyledSmallHeader>
                </Box>
                <Box flex justify="center">
                  <Text color={textColor} size="1rem" textAlign={small ? 'center' : 'start'}>
                    {dt.text}
                  </Text>
                </Box>
                <Box flex justify="end">
                  {renderIconButtons(i, content.length, nextSlide, prevSlide, textColor, highlight)}
                </Box>
              </StyledUseCaseSlideContainer>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

UseCaseSlider.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  boxShadow: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  slideBg: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
};

export default UseCaseSlider;
