import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box } from 'grommet';

import { LazyResponsiveImageLoader } from '@/components/Control';
import { optimizeImageSrc } from '@/helpers/util';
import { ScrollerHeader } from './ContentSections';
import {
  StyledTextContent, StyledIntroTextAnchor, StyledAnchorButtonWrapper,
} from './StyledContentSections';

const AnchorButton = ({
  small,
  title,
  textColor,
  path,
  icon,
  buttonColor,
  iconBg,
  iconBorderColor,
  borderImage,
}) => (
  <StyledIntroTextAnchor path={path} outlineColor={buttonColor} borderImage={borderImage}>
    <Box
      direction="row"
      gap="1rem"
      justify="start"
      align="center"
      pad={{ right: '0.65rem' }}
    >
      <Box
        className="iconContainer"
        round="34px"
        background={iconBg}
        align="center"
        justify="center"
        border={{
          style: 'solid',
          side: 'all',
          color: iconBorderColor,
          size: '3px',
        }}
      >
        <LazyResponsiveImageLoader
          src={() => (small ? `${optimizeImageSrc(icon)}&w=350` : optimizeImageSrc(icon))}
          alt={`${title} icon`}
          fit="contain"
        />
      </Box>
      <StyledTextContent color={textColor} weight={600} textAlign="start" size={small ? '16px' : '18px'}>
        {title}
      </StyledTextContent>
    </Box>
  </StyledIntroTextAnchor>
);

AnchorButton.propTypes = {
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconBg: PropTypes.string.isRequired,
  iconBorderColor: PropTypes.string.isRequired,
  borderImage: PropTypes.string.isRequired,
};

const IntroTextScroller = ({
  small,
  textColor,
  header,
  subHeader,
  buttonColor,
  iconBg,
  iconBorderColor,
  anchorButtons,
  borderImage,
}) => (
  <Box overflow="hidden" direction={small ? 'column' : 'row'} justify="between">
    <Box flex direction="column" gap="0.5rem" justify="center" pad={{ bottom: small ? '2rem' : '0rem', right: small ? '0rem' : '1.5rem' }}>
      <ScrollerHeader header={header} color={textColor} textAlign={small ? 'center' : 'start'} />
      <StyledTextContent color={textColor} textAlign={small ? 'center' : 'start'}>
        {subHeader}
      </StyledTextContent>
    </Box>
    <Box flex gap="2rem" align={small ? 'center' : 'end'} width={{ min: 'fit-content' }}>
      <StyledAnchorButtonWrapper wrap={false} gap="2rem">
        {anchorButtons.slice(0, 2).map(({ title, icon, path }) => (
          <AnchorButton
            key={uniqid()}
            small={small}
            path={path}
            title={title}
            textColor={textColor}
            icon={icon}
            buttonColor={buttonColor}
            iconBg={iconBg}
            iconBorderColor={iconBorderColor}
            borderImage={borderImage}
          />
        ))}
      </StyledAnchorButtonWrapper>
      <StyledAnchorButtonWrapper wrap={false} gap="2rem">
        {anchorButtons.slice(2, 4).map(({ title, icon, path }) => (
          <AnchorButton
            key={uniqid()}
            small={small}
            path={path}
            title={title}
            textColor={textColor}
            icon={icon}
            buttonColor={buttonColor}
            iconBg={iconBg}
            iconBorderColor={iconBorderColor}
            borderImage={borderImage}
          />
        ))}
      </StyledAnchorButtonWrapper>
    </Box>
  </Box>
);

IntroTextScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  iconBg: PropTypes.string.isRequired,
  iconBorderColor: PropTypes.string.isRequired,
  borderImage: PropTypes.string.isRequired,
  anchorButtons: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })).isRequired,
};

export default IntroTextScroller;
